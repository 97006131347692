import Vue from 'vue'
import uniqueId from 'lodash/uniqueId'

function createTooltip(text: string, position: string): HTMLElement {
  const tooltip = document.createElement('div')
  const arrow = document.createElement('div')
  const inner = document.createElement('div')
  tooltip.appendChild(arrow)
  tooltip.appendChild(inner)
  tooltip.setAttribute('class', 'tooltip fade bs-tooltip-auto show')
  tooltip.setAttribute('id', `tooltip-${uniqueId()}`)
  tooltip.setAttribute('x-placement', position)
  arrow.setAttribute('class', 'arrow')
  inner.setAttribute('class', 'tooltip-inner')
  inner.textContent = text
  inner.style.padding = '.25rem .5rem'
  tooltip.style.position = 'absolute'
  switch (position) {
    case 'top':
    case 'bottom':
      arrow.style.left = '50%'
      arrow.style.transform = 'translateX(-50%)'
      break
    case 'left':
    case 'right':
      arrow.style.top = '50%'
      arrow.style.transform = 'translateY(-50%)'
      break
  }
  return tooltip
}

function getOffset(elem: HTMLElement): { top: number; left: number } {
  if (!elem.getClientRects().length) {
    return { top: 0, left: 0 }
  }
  const rect = elem.getBoundingClientRect()
  if (rect.width || rect.height) {
    const doc = elem.ownerDocument
    if (!doc) {
      return { top: 0, left: 0 }
    }
    const win = window
    const docElem = doc.documentElement

    return {
      top: rect.top + win.pageYOffset - docElem.clientTop,
      left: rect.left + win.pageXOffset - docElem.clientLeft
    }
  }
  return rect
}

function buildMouseOverHandler(target: HTMLElement, position: string, text: string) {
  return (e: MouseEvent) => {
    const tooltipId = target.getAttribute('data-tooltip-id') || ''
    const prevTooltip = document.getElementById(tooltipId)
    if (prevTooltip) {
      return
    }

    const offset = getOffset(target)
    const tooltip = createTooltip(text, position)
    target.setAttribute('data-tooltip-id', tooltip.id)
    tooltip.style.display = 'block'
    if (!document.getElementById(tooltip.id)) {
      document.body.appendChild(tooltip)
    }

    const handleTooltipMouseOver = (e: MouseEvent) => {
      removeTooltip(target, tooltip)
    }
    const tooltipStore = tooltipStores.find(it => it.target === target)
    if (tooltipStore && tooltipStore.show) {
      tooltip.addEventListener('mouseover', handleTooltipMouseOver)
      tooltipStore.handleTooltipMouseOver = handleTooltipMouseOver
    } else {
      return
    }

    let top, left
    switch (position) {
      case 'top':
        top = offset.top - tooltip.offsetHeight
        left = offset.left + target.offsetWidth / 2 - tooltip.offsetWidth / 2
        break
      case 'left':
        top = offset.top + target.offsetHeight / 2 - tooltip.offsetHeight / 2
        left = offset.left - tooltip.offsetWidth
        break
      case 'right':
        top = offset.top + target.offsetHeight / 2 - tooltip.offsetHeight / 2
        left = offset.left + target.offsetWidth
        break
      case 'bottom':
        top = offset.top + target.offsetHeight
        left = offset.left + target.offsetWidth / 2 - tooltip.offsetWidth / 2
        break
    }
    tooltip.style.top = `${top}px`
    tooltip.style.left = `${left}px`

    tooltipStores.forEach((tooltipStore: TooltipStore) => {
      if (tooltipStore.target === target) {
        return
      }
      const tooltip = document.getElementById(tooltipStore.target.getAttribute('data-tooltip-id')!)
      if (tooltip) {
        removeTooltip(tooltipStore.target, tooltip)
      }
    })
  }
}

function buildMouseOutHandler() {
  return (e: MouseEvent) => {
    const target = e.target! as HTMLElement
    const tooltipId = target.getAttribute('data-tooltip-id')!
    const tooltip = document.getElementById(tooltipId)
    if (tooltip) {
      removeTooltip(target, tooltip)
    }
  }
}

function removeTooltip(target: HTMLElement, tooltip: HTMLElement) {
  const tooltipStore = tooltipStores.find(it => it.target === target)
  if (tooltipStore && tooltipStore.handleTooltipMouseOver) {
    tooltip.removeEventListener('mouseover', tooltipStore.handleTooltipMouseOver)
  }
  tooltip.remove()
}

type TooltipStore = {
  target: HTMLElement
  text: string
  show: boolean
  handleMouseOver: (e: MouseEvent) => void
  handleMouseOut: (e: MouseEvent) => void
  handleTooltipMouseOver?: (e: MouseEvent) => void
}
const tooltipStores: Array<TooltipStore> = []

Vue.directive('tooltip', {
  bind(target, binding) {
    const text = binding.value instanceof Object ? binding.value.text : binding.value
    const position = Object.keys(binding.modifiers)[0] || 'top'
    const show = binding.value.show !== undefined ? binding.value.show : true
    const tooltipStore = {
      target,
      text,
      show: show,
      handleMouseOver: buildMouseOverHandler(target, position, text),
      handleMouseOut: buildMouseOutHandler()
    }
    target.addEventListener('mouseover', tooltipStore.handleMouseOver)
    target.addEventListener('mouseout', tooltipStore.handleMouseOut)
    tooltipStores.push(tooltipStore)
  },

  update(target, binding) {
    const index = tooltipStores.findIndex(it => it.target === target)
    const show = binding.value.show !== undefined ? binding.value.show : true
    tooltipStores[index].show = show
  },

  unbind(target) {
    const tooltipStore = tooltipStores.find(it => it.target === target)
    if (!tooltipStore) {
      return
    }
    target.removeEventListener('mouseover', tooltipStore.handleMouseOver)
    target.removeEventListener('mouseout', tooltipStore.handleMouseOut)

    const index = tooltipStores.indexOf(tooltipStore)
    tooltipStores.slice(index, 1)
  }
})

import Vue from 'vue'
import VueI18n, { LocaleMessages, DateTimeFormats } from 'vue-i18n'

Vue.use(VueI18n)

function buildLocales<T>(locales: __WebpackModuleApi.RequireContext): T {
  return locales.keys().reduce((acc, key) => {
    const locale = key.match(/([A-Za-z0-9-_]+)\./i)![1]
    return { ...acc, [locale]: locales(key) }
  }, {} as T)
}

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', false, /.+\.json$/i)
  return buildLocales<LocaleMessages>(locales)
}

function loadDateTimeFormats(): DateTimeFormats {
  const locales = require.context('./locales/dateTimeFormats', false, /.+\.json$/i)
  return buildLocales<DateTimeFormats>(locales)
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats: loadDateTimeFormats()
})

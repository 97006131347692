import User from '@/core/models/User'
export class WorkBlockTaskSummary {
  readonly needReview: boolean
  readonly totalCount: number
  readonly notYetCount: number
  readonly inReviewCount: number
  readonly completeCount: number
  readonly pendingCount: number
  readonly discardCount: number

  constructor(needReview: boolean, json: any) {
    this.needReview = needReview
    this.totalCount = json.total_count
    this.notYetCount = json.not_yet_count
    this.inReviewCount = json.in_review_count
    this.completeCount = json.complete_count
    this.pendingCount = json.pending_count
    this.discardCount = json.discard_count
  }
  get processedCount() {
    if (this.needReview) {
      return this.completeCount - this.inReviewCount
    } else {
      return this.completeCount
    }
  }
}

export class WorkBlockTaskPerformance {
  readonly date: Date
  readonly total: number
  readonly notyet: number
  readonly annotated: number
  readonly reviewed: number
  readonly pending: number
  readonly discard: number
  readonly worktimeAverage: number

  constructor(json: any) {
    this.date = new Date(json.date)
    this.total = json.total
    this.notyet = json.notyet
    this.annotated = json.annotated
    this.reviewed = json.reviewed
    this.pending = json.pending
    this.discard = json.discard
    this.worktimeAverage = json.worktime_average
  }
  get needToWork() {
    return this.total - this.annotated - this.reviewed - this.discard
  }
}
export class WorkBlockAnnotatorPerformance {
  readonly user: User
  readonly performance: {
    readonly date: Date
    readonly annotated: number
    readonly reviewed: number
    readonly rejected: number
    readonly pending: number
    readonly discard: number
    readonly worktimeAverage: number
  }[]

  constructor(json: any) {
    this.user = json.user
    this.performance = json.performance.map((p: any) => {
      return {
        date: new Date(p.date),
        annotated: p.annotated,
        reviewed: p.reviewed,
        rejected: p.rejected,
        pending: p.pending,
        discard: p.discard,
        worktimeAverage: p.worktime_average
      }
    })
  }

  get totalCount() {
    return this.performance.map(p => p.annotated).reduce((a, b) => a + b)
  }
  get totalRejectCount() {
    return this.performance.map(p => p.rejected).reduce((a, b) => a + b)
  }
  get rejectRate() {
    const { totalCount, totalRejectCount } = this
    if (totalCount === 0 || totalRejectCount === 0) {
      return 0
    }
    const rate = (totalRejectCount / totalCount) * 100
    return Number(rate.toFixed(1))
  }
  // Comment out the work time as it will be out of scope in the first release.
  // get workingTimeAvg() {
  //   const sum = this.performance
  //     .map(p => p.worktimeAverage)
  //     .reduce((a,b) => a + b)
  //   const avg = (sum / this.performance?.length) / 1000
  //   return numeral(avg).format('00:00')
  // }
}

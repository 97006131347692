import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { VueGtmTrackEventParams } from 'vue-gtm/src/plugin'

export class GTM {
  static init(router: any) {
    if (process.env.VUE_APP_GTM_ID) {
      Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM_ID,
        enabled: true,
        debug: process.env.NODE_ENV !== 'production',
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: true
      })
    }
  }
  static trackEvent = (data: VueGtmTrackEventParams): void => {
    if (!process.env.VUE_APP_GTM_ID || !Vue.gtm) {
      return
    }
    Vue.gtm.trackEvent(data)
  }
}

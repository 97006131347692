import IAssignable from '@/core/models/IAssignable'
import { httpClient } from '@/core/common/HttpClient'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'
import ResultsSearchCondition from '@/core/models/ResultsSearchCondition'
import Result from '@/core/models/Result'
import { ResultSummary } from './ResultSummary'
import { SourceURI } from '@/core/models/SourceURI'
import { Paginator } from './CursorPaginator'
import SearchOption from '../interfaces/SearchOption'
import ResultStatus from '@/core/models/ResultStatus'
import Task from '@/core/models/Task'
import TaskCount from './TaskCount'
import SourceURIScheme from './SourceURIScheme'
import ProjectStatus from './ProjectStatus'
import { TaskImportHistoryPayload } from './TaskImportHistory'
import TaskImportHistory from '@/core/models/TaskImportHistory'
import Assignments from '@/core/models/Assignments'
import ExportEvent from '@/core/models/ExportEvent'
import WorkSource, { IWorkSourcePayload } from '@/core/models/WorkSource'
import { Dataset } from './Dataset'
import { ExportDatasetEvent } from './ExportDatasetEvent'

interface WorkPayload {
  id: number
  name: string
  goal_point: number
  created_at: string
  updated_at: string
  source_uri: {
    scheme: number
    host: string
    uri_path: string
  }
  work_block_id: number
  importable: boolean
  review_required: boolean
  label_categories: number[]
  summary: any
  project: {
    created_at: string
    description: string
    due_date: string
    id: number
    name: string
    owner_organization_id: number
    status: number
    updated_at: string
  }
}

export interface ProjectInfo {
  name: string
  id: number
  status: ProjectStatus
  ownerOrganizationId: number
}

export class Work {
  constructor(workBlockID: number, projectInfo?: ProjectInfo) {
    this.workBlockID = workBlockID
    this.project = projectInfo
    this.projectID = projectInfo?.id
  }

  static mapObject(data: WorkPayload): Work {
    let projectInf
    if (data.project) {
      projectInf = {
        name: data.project.name,
        id: data.project.id,
        status: ProjectStatus.getById(data.project.status),
        ownerOrganizationId: data.project.owner_organization_id
      }
    }
    const work = new Work(data.work_block_id, projectInf)
    work.id = data.id
    work.name = data.name
    work.workBlockID = data.work_block_id
    work.goalPoint = data.goal_point
    if (data.summary) {
      work.summary = ResultSummary.mapObject(data.summary)
    }
    work.importable = data.importable
    work.reviewRequired = data.review_required
    work.labelCategories = data.label_categories
    work.createdAt = new Date(data.created_at)
    work.updatedAt = new Date(data.updated_at)
    if (data.source_uri) {
      if (data.source_uri.scheme === SourceURIScheme.DATALAKE.id) {
        work.platformChannelID = parseInt(data.source_uri.host)
      }
      work.sourceURI = new SourceURI(data.source_uri)

      // TODO: Copy from project, need to merge it with work.sourceURI
      const sourceSchemeID = data.source_uri.scheme
      work.sourceScheme = SourceURIScheme.getById(sourceSchemeID)
    }
    return work
  }
  id = NaN
  name = ''
  // FIXME We can use project.id. Can remove later
  projectID?: number
  workBlockID?: number
  goalPoint = 10
  labelCategories: number[] = []
  platformChannelID: number | null = null
  project?: ProjectInfo
  public sourceURI?: SourceURI
  sourceScheme: SourceURIScheme = SourceURIScheme.UNKNOWN
  taskImportHistory: TaskImportHistory[] = []
  exportEvents: ExportEvent[] = []
  importable = true
  createdAt?: Date
  updatedAt?: Date
  reviewRequired?: boolean

  summary?: ResultSummary
  assignments: Assignments = new Assignments()

  getAssignedAnnotators(organizationId: number): Promise<Annotator[]> {
    return httpClient
      .get(`/api/v1/works/${this.id}/units/${organizationId}/assigns`)
      .then((a: any) => (a || []).map(Annotator.mapObject))
  }
  getAssignedUnits(clientOrganizationID: number): Promise<BPO[]> {
    return httpClient
      .get(`${this.getWorkManagementAPIUrl(clientOrganizationID)}/units`)
      .then((a: any) => (a || []).map(BPO.mapObject))
  }
  assignAnnotator(organizationId: number, assignable: IAssignable[]): Promise<IAssignable[]> {
    return httpClient
      .put(`/api/v1/works/${this.id}/units/${organizationId}/assigns`, {
        annotators: assignable.map(a => a.id)
      })
      .then((a: any) => (a || []).map(Annotator.mapObject))
  }

  async assignBPO(clientOrganizationID: number, assignable: IAssignable[]): Promise<BPO[]> {
    const payload = assignable.map(a => {
      return {
        unit_id: a.id,
        number_of_task: this.goalPoint
      }
    })
    const response: any = await httpClient.put(`${this.getWorkManagementAPIUrl(clientOrganizationID)}/units`, payload)
    return response.map((bpo: any) => BPO.mapObject(bpo))
  }

  getResultPaginator(searchCondition: SearchOption): Paginator<Result> {
    const baseURL = `/api/v1/works/${this.id}/results`
    return new Paginator<Result>(baseURL, searchCondition, Result.mapObject)
  }

  async getResultSummary(condition: ResultsSearchCondition): Promise<ResultSummary> {
    let query = condition.toSummaryQueryParameter()
    if (query) {
      query = '?' + query
    }
    const response = await httpClient.get(`/api/v1/works/${this.id}/result_summary${query}`)
    const summary = ResultSummary.mapObject(response)
    return summary
  }

  async getTaskCount(): Promise<TaskCount> {
    const response: any = await httpClient.get(
      // eslint-disable-next-line max-len
      `/api/v1/organizations/${this.project?.ownerOrganizationId}/projects/${this.project?.id}/blocks/${this.workBlockID}/works/${this.id}/task_count`
    )
    return new TaskCount(response.total_task_count, response.imported_task_count)
  }

  async getTasks(status?: ResultStatus): Promise<any> {
    let query = ''
    if (status) {
      query = '?exclude_unassigned=true&status=' + status.id
    }
    const response = await httpClient.get<{ data: any[] }>(`/api/v1/works/${this.id}/tasks${query}`)
    return response.data.map(d => Task.mapObject(d))
  }

  async batchUpdateStatus(toStatus: ResultStatus, resultIds: number[]): Promise<any> {
    return httpClient.put(`/api/v1/works/${this.id}/results_batch_update`, {
      result_ids: resultIds,
      status: toStatus.id
    })
  }

  async getNewTask(taskID: number | null): Promise<Result> {
    let params = {}
    if (taskID) {
      params = { task_id: taskID }
    }
    const path = `/api/v1/works/${this.id}/results`
    const payload = await httpClient.post(path, params)
    return Result.mapObject(payload)
  }

  async getImportTasks(ownerOrganizationID: number): Promise<TaskImportHistory[]> {
    const response: any = await httpClient.get(`${this.getWorkManagementAPIUrl(ownerOrganizationID)}/importtasks`)
    const importHistory = (response || []).map((item: any) => TaskImportHistory.mapObject(item))
    this.taskImportHistory = importHistory
    return importHistory
  }

  static async getCurrentWork(id: number): Promise<Work> {
    const response: WorkPayload = await httpClient.get(`/api/v1/works/${id}`)
    return Work.mapObject(response)
  }

  async updateWork(work: Work, ownerOrganizationID: number): Promise<Work> {
    const response: WorkPayload = await httpClient.put(
      `/api/v1/organizations/${ownerOrganizationID}` +
        `/projects/${this.project?.id}/blocks/${this.workBlockID}/works/${this.id}`,
      {
        name: work.name,
        goal_point: work.goalPoint
      }
    )
    return Work.mapObject(response)
  }

  async deleteWork(ownerOrganizationID: number): Promise<Work> {
    return httpClient.delete(
      `/api/v1/organizations/${ownerOrganizationID}` +
        `/projects/${this.project?.id}/blocks/${this.workBlockID}/works/${this.id}`
    )
  }

  static getWorkPaginator(searchParams: SearchOption): Paginator<Work> {
    const baseURL = `/api/v1/works`
    return new Paginator<Work>(baseURL, searchParams, Work.mapObject)
  }

  get canWork(): boolean {
    const haveWorkableTasks = this.summary?.notYetCount !== 0
    return this.project!.status.canWork && haveWorkableTasks
  }

  get canReview(): boolean {
    return this.project!.status.canReview
  }

  get canEditResult(): boolean {
    return this.project!.status.canEditResult
  }

  get canManageAssignment(): boolean {
    return this.project!.status.canManageAssignment
  }

  get isNew(): boolean {
    return Number.isNaN(this.id)
  }

  get isDataLake(): boolean {
    return this.isSourceSchemeEqual(SourceURIScheme.DATALAKE)
  }

  get isCSV(): boolean {
    return this.isSourceSchemeEqual(SourceURIScheme.GCS)
  }

  get isS3(): boolean {
    return this.isSourceSchemeEqual(SourceURIScheme.S3)
  }

  get isGCS(): boolean {
    return this.isSourceSchemeEqual(SourceURIScheme.GS)
  }

  get isExportResultToCloudStorage() {
    return this.isS3 || this.isGCS
  }

  private isSourceSchemeEqual(scheme: SourceURIScheme): boolean {
    if (!this.sourceScheme) {
      return false
    }
    return this.sourceScheme.id === scheme.id
  }

  get isUnknownSourceScheme(): boolean {
    return !this.sourceScheme || this.sourceScheme.id === SourceURIScheme.UNKNOWN.id
  }

  get hasAnyAssignments(): boolean {
    return this.assignments.assignedCount > 0
  }

  async create(): Promise<Work> {
    const { name, goalPoint, labelCategories } = this
    const params = {
      name,
      goal_point: goalPoint,
      source_scheme: this.sourceScheme.id,
      host: this.sourceURI?.host,
      uri_path: this.sourceURI?.uriPath,
      label_categories: labelCategories,
      importable: this.importable,
      review_required: this.reviewRequired
    } as any
    if (this.isDataLake) {
      params.channel_id = this.platformChannelID
    }
    const url = `${this.getWorkBlockAPIUrl()}/works`
    const response: any = await httpClient.post(url, params)
    const newWork = Work.mapObject(response)

    // Management API did not return project info
    // We need to append project info. But it is no good solution.
    newWork.project = this.project

    return newWork
  }

  async createImportTask(sourceURI?: SourceURI) {
    const path = `${this.getWorkManagementAPIUrl()}/importtasks`

    const payload = !sourceURI
      ? {}
      : { source_uri: { scheme: sourceURI.scheme.id, host: sourceURI.host, uri_path: sourceURI.uriPath } }
    const data = await httpClient.post<TaskImportHistoryPayload>(path, payload)

    this.taskImportHistory.push(TaskImportHistory.mapObject(data))
  }

  async uploadCSV(file: File) {
    const path = `${this.getWorkManagementAPIUrl()}/sources`
    const res1 = (await httpClient.post(path, {
      mime_type: 'text/csv',
      filename: file.name
    })) as any
    await httpClient.put(res1.upload_url, file, {
      headers: { 'Content-Type': 'text/csv' },
      withCredentials: false
    })
    const sourceURI = new SourceURI(res1.uri)
    await this.createImportTask(sourceURI)
  }

  async getWorkSources(): Promise<Array<WorkSource>> {
    const path = `${this.getWorkManagementAPIUrl()}/sources`
    const data = await httpClient.get<Array<IWorkSourcePayload>>(path)
    return data.map((data: IWorkSourcePayload) => new WorkSource(data))
  }

  taskFinished() {
    this.summary!.notYetCount = 0
  }

  private getWorkBlockAPIUrl(clientOrganizationId?: number): string {
    const orgId = clientOrganizationId ?? this.project?.ownerOrganizationId
    const prjId = this.project?.id
    const workBlockId = this.workBlockID
    if (!orgId || !prjId || !workBlockId) {
      throw new Error(
        'Can not call API. Because work did not have enough ids. ' +
          `[organization id:${orgId}, project id:${prjId}, work block id:${workBlockId}]`
      )
    }
    return `/api/v1/organizations/${orgId}/projects/${prjId}/blocks/${workBlockId}`
  }

  private getWorkManagementAPIUrl(clientOrganizationId?: number): string {
    return `${this.getWorkBlockAPIUrl(clientOrganizationId)}/works/${this.id}`
  }

  get isEnd(): boolean {
    return this.summary!.notYetCount <= 0
  }

  get isTaskEmpty(): boolean {
    return this.summary!.totalCount <= 0
  }

  async getExportEvents(organizationID: number): Promise<ExportEvent[]> {
    const response: any = await httpClient.get(`${this.getWorkBlockAPIUrl(organizationID)}/exports?work_id=${this.id}`)
    const exportEvents = (response || []).map((res: any) => ExportEvent.mapObject(res))
    this.exportEvents = exportEvents
    return exportEvents
  }

  async getDatasetExportEvents(organizationID: number): Promise<ExportDatasetEvent[]> {
    const response: any = await httpClient.get(
      `${this.getWorkBlockAPIUrl(organizationID)}/dataset_exports?work_id=${this.id}`
    )
    const events = response.result.map((res: any) => ExportDatasetEvent.mapObject(res))
    return events
  }

  async createExportEvent(
    fileType: string,
    organizationID: number,
    exportChannelID?: string,
    outputUri?: string
  ): Promise<ExportEvent> {
    const payload: {
      work_id: number
      datalake_channel_id?: string
      output_source_uri?: string
    } = {
      work_id: this.id
    }
    if (exportChannelID) {
      payload.datalake_channel_id = exportChannelID
    }
    if (outputUri) {
      payload.output_source_uri = outputUri
    }
    const response: any = await httpClient.post(
      `${this.getWorkBlockAPIUrl(organizationID)}/exports?work_id=${this.id}`,
      payload
    )
    const exportEvent = ExportEvent.mapObject(response)
    this.exportEvents = [exportEvent, ...this.exportEvents]
    return exportEvent
  }

  async createExportDatasetEvent(
    organizationID: number,
    dataset: Dataset,
    exportChannelID?: string
  ): Promise<ExportDatasetEvent> {
    const payload: {
      work_id: number
      datalake_channel_id?: string
      name: string
      description?: string
    } = {
      work_id: this.id,
      name: dataset.name,
      description: dataset.description
    }
    if (exportChannelID) {
      payload.datalake_channel_id = exportChannelID
    }
    const response: any = await httpClient.post(`${this.getWorkBlockAPIUrl(organizationID)}/dataset_exports`, payload)
    const createdEvent = ExportDatasetEvent.mapObject(response)
    return createdEvent
  }
}

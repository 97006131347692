import { httpClient } from '../common/HttpClient'

const API_BASE_PATH = '/api/v1/access_tokens'

interface AccessTokenPayload {
  id: number
  name: string
  token?: string
  created_at: string
}

export default class AccessToken {
  id: number
  name: string
  token: string | null
  createdAt: Date

  constructor(data: AccessTokenPayload) {
    this.id = data.id
    this.name = data.name
    this.token = data.token || null
    this.createdAt = new Date(data.created_at)
  }

  static async getAccessTokens(organization_id: number): Promise<AccessToken[]> {
    const config = { params: { organization_id } }
    const payload = await httpClient.get<AccessTokenPayload[]>(API_BASE_PATH, config)
    return payload.map(it => new AccessToken(it)) as AccessToken[]
  }

  static async createAccessToken(organization_id: number, name: string): Promise<AccessToken> {
    const payload = await httpClient.post<AccessTokenPayload>(API_BASE_PATH, {
      organization_id,
      name
    })
    return new AccessToken(payload)
  }

  static async deleteAccessToken(id: number) {
    return await httpClient.delete(`${API_BASE_PATH}/${id}`)
  }
}

import { RouteConfig } from 'vue-router'

/**
 * レビューと作業結果画面へのrouter
 * 同じcomponent に遷移するが、meta.breadcrumb を使い分けてtitle 表示を変えている
 */
const route: RouteConfig[] = [
  // レビュー画面用
  {
    path: '/review/:project_id',
    name: 'review',
    component: () => import('./pages/Review.vue'),
    meta: {
      resource: 'result',
      action: 'list',
      breadcrumb: 'review',
      gtm: 'Review'
    },
    props: true
  },
  // 作業結果画面（レビュー不要ワーク）用
  {
    path: '/workresult/:project_id',
    name: 'workresult',
    component: () => import('./pages/Review.vue'),
    meta: {
      resource: 'result',
      action: 'list',
      breadcrumb: 'workResult',
      gtm: 'Work Result'
    },
    props: true
  }
]
export default route

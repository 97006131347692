import Comment from '@/core/models/Comment'
import User from '@/core/models/User'

export default class ReviewStream {
  constructor(
    public readonly taskId: number,
    public readonly resultId: number,
    // TODO Remove null after marge PR to return user info in result response
    public readonly annotator: User | undefined,
    public readonly comments: Comment[]
  ) {}
}

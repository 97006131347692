import IAssignable from './IAssignable'
import AnnotationSummary from '@/core/models/AnnotationSummary'

export default class BPO implements IAssignable {
  static mapObject(data: any) {
    const bpo = new BPO(data.id, data.name)
    if (data.statistic) {
      const { in_review_count, complete_count, reject_count, solved_reject_count } = data.statistic
      bpo.summary = new AnnotationSummary(0, in_review_count, complete_count, reject_count, solved_reject_count)
    }
    return bpo
  }
  summary: AnnotationSummary = new AnnotationSummary(0, 0, 0, 0, 0)
  private constructor(public id: number, public name: string) {}

  equals(compareTarget: IAssignable): boolean {
    return !!compareTarget && compareTarget instanceof BPO && compareTarget.id === this.id
  }
}

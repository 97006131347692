import { MutationTree } from 'vuex'
import { IProjectState } from './index'
import Project from '@/core/models/Project'
import IAssignable from '@/core/models/IAssignable'
import cloneDeep from 'lodash/cloneDeep'
import Annotator from '@/core/models/Annotator'
import ProjectStatus from '@/core/models/ProjectStatus'
import Template from '@/core/models/Template'
import Assignments from '@/core/models/Assignments'

export const mutations: MutationTree<IProjectState> = {
  setCurrentProject(state, payload: Project) {
    state.currentProject = payload
  },
  updateCurrentProject(state) {
    state.currentProject = cloneDeep<Project>(state.currentProject!)
  },
  updateAssignedAnnotatorInCurrentProject(state, assignment: IAssignable[]) {
    state.currentProject!.defaultAssignments = new Assignments(assignment)
    state.currentProject = cloneDeep<Project>(state.currentProject!)
  },
  updateProjectStatus(state, newStatus: ProjectStatus) {
    state.currentProject!.status = newStatus
    state.currentProject = cloneDeep<Project>(state.currentProject!)
  },
  addAssignedAnnotator(state, annotator: IAssignable) {
    const { currentProject } = state
    if (!currentProject) {
      return
    }
    currentProject!.defaultAssignments.add(annotator)
    state.currentProject = cloneDeep<Project>(currentProject)
  },
  setAssignableAnnotators(state, payload: Annotator[]) {
    state.assignableAnnotators = payload
  },
  setAssignableBPOs(state, payload: IAssignable[]) {
    state.assignableBPOs = payload
  },
  setTemplates(state, templates: Template[]) {
    state.templates = templates
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import createStore from './store/'
import features from '@/features/feature'
import i18n from './i18n'
import VModal from 'vue-js-modal'
import VueSweetalert2 from 'vue-sweetalert2'

import './assets/styles/app.scss'
import '@/filters'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from '@/ability'
import { initSentry } from '@/config/sentry'
import './directives/tooltip'
import Toast from '@/plugins/toast'
import Alert from '@/plugins/alert'
import ConfirmModal from '@/plugins/confirm'
import { GTM } from './config/gtm'
import '@/utils/validation'

initSentry(router)

Vue.use(VueSweetalert2)
Vue.use(Toast)
Vue.use(Alert)
Vue.use(ConfirmModal)

Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true })

Vue.use(abilitiesPlugin, ability)

export const store = createStore(features)

GTM.init(router)

if (process.env.VUE_APP_STAGE === 'production') {
  // For production build
  Vue.config.productionTip = false
} else {
  // For developer build
  Vue.config.devtools = true
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/performance'
import User from '@/core/models/User'
import CollectionReference = firebase.firestore.CollectionReference
import DocumentReference = firebase.firestore.DocumentReference
import Query = firebase.firestore.Query
import { ResultSummary } from '@/core/models/ResultSummary'

firebase.initializeApp({
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY
})

export const db = firebase.firestore()
export const perf = firebase.performance()
export const auth = firebase.auth()
export { firebase }
const usersCollection: CollectionReference = db.collection('users')
const announcementsCollection: CollectionReference = db.collection('announcements')
const worksCollection: CollectionReference = db.collection('works')

export const collections = {
  usersCollection,
  userNotificationsCollection: (user: User, organizationID: number): Query => {
    const query = usersCollection
      .doc(String(user.id))
      .collection('notifications')
      .where('organizationId', '==', organizationID)
    return query
  },
  announcementsCollection
}

export const docs = {
  userProfile: (user: User): DocumentReference => usersCollection.doc(String(user.id)),
  workProgress: (workId: number) => worksCollection.doc(`${workId}`)
}

export const queries = {
  userNotifications: (user: User, organizationID: number, limit: number) => {
    return collections
      .userNotificationsCollection(user, organizationID)
      .orderBy('timestamp', 'desc')
      .limit(limit)
  },
  announcements: (limit: number) =>
    collections.announcementsCollection
      .where('active', '==', true)
      .where('publishTime', '<=', new Date())
      .orderBy('publishTime', 'desc')
      .limit(limit)
}

export const operation = {
  readNotification: async (user: User, organizationID: number) => {
    const ref = await collections
      .userNotificationsCollection(user, organizationID)
      .where('read', '==', false)
      .get()
    ref.docs.forEach(d =>
      d.ref.update({
        read: true
      })
    )
  },
  updateLastReadAnnouncment: async (user: User, lastReadAnnounce: number) => {
    docs.userProfile(user!).set({ lastReadAnnounce })
  }
}

// This is dummy action for notification
// Need to remove after post notification in server
export const dummyAction = {
  postAnnouncement: async (body: string, title: string) => {
    const timestamp = firebase.firestore.Timestamp.now()
    await announcementsCollection.add({
      title,
      body,
      timestamp
    })
  },
  postSummary: async (workId: number, summary: ResultSummary, goalCount: number) => {
    const doc = await worksCollection.doc(`${workId}`)
    const { completeCount, totalCount, inReviewCount } = summary
    if (doc) {
      doc.set({
        goalCount,
        completeCount,
        totalCount,
        inReviewCount
      })
    } else {
      await worksCollection.add({
        id: `${workId}`,
        goalCount,
        completeCount,
        totalCount,
        inReviewCount
      })
    }
  }
}

import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { Module } from 'vuex'
import { IRootState } from '@/store'
import Task from '@/core/models/Task'
import Project from '@/core/models/Project'
import Result from '@/core/models/Result'
import Template from '@/core/models/Template'
import { Work } from '@/core/models/Work'

export interface IWorkspaceState {
  currentProject: Project | null
  currentWork: Work | null
  currentTask: Task | null
  currentResult: Result | null
  currentTemplate: Template | null
  taskProgress: null
}

const state = {
  currentProject: null,
  currentWork: null,
  taskProgress: null,
  currentTask: null,
  currentResult: null,
  currentTemplate: null
}

const workspace: Module<IWorkspaceState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default workspace

import { httpClient } from '../common/HttpClient'
import IAssignable from './IAssignable'
import AnnotationSummary from '@/core/models/AnnotationSummary'
import User from '@/core/models/User'
import ProjectSummaryCollection from '@/core/models/ProjectSummaryCollection'

export default class Annotator extends User implements IAssignable {
  static mapObject(data: any): Readonly<Annotator> {
    return new Annotator(data)
  }

  static async createAnnotator(organizationID: number, newAnnotator: Annotator): Promise<Annotator> {
    const { name, email, password, role } = newAnnotator
    if (!password) {
      throw Error('Password is empty')
    }
    const response: any = await httpClient.post(`/api/v1/organizations/${organizationID}/users`, {
      name,
      email,
      role: role.id,
      password
    })
    return new Annotator(response)
  }

  static async getDetailSummaries(organizationID: number, userID: string): Promise<ProjectSummaryCollection> {
    return ProjectSummaryCollection.getStatistic(organizationID, userID)
  }

  summary: AnnotationSummary
  password = ''

  private constructor(d: any) {
    super(d)
    if (d.statistic) {
      const { project_count, in_review_count, complete_count, reject_count, solved_reject_count } = d.statistic
      this.summary = new AnnotationSummary(
        project_count,
        in_review_count,
        complete_count,
        reject_count,
        solved_reject_count
      )
    } else {
      this.summary = new AnnotationSummary(0, 0, 0, 0, 0)
    }
  }

  async updateAnnotator(organizationID: number): Promise<Annotator> {
    const data: {
      name?: string
      email?: string
      role: number
      password?: string
      photo_uri?: string
    } = {
      role: this.role.id
    }
    // only InHouseAccount can update profile
    if (this.isInHouseAccount) {
      data.name = this.name
      if (!this.emailVerified) {
        data.email = this.email
      }
      if (this.password) {
        data.password = this.password
      }
      if (this.photoURI) {
        data.photo_uri = this.photoURI
      }
    }
    const response = await httpClient.put(`/api/v1/organizations/${organizationID}/users/${this.id}`, data)
    return new Annotator(response)
  }

  async delete(organizationID: number): Promise<void> {
    await httpClient.delete(`/api/v1/organizations/${organizationID}/users/${this.id}`)
  }

  equals(compareTarget: IAssignable): boolean {
    return !!compareTarget && compareTarget instanceof Annotator && compareTarget.id === this.id
  }
}

import Vue from 'vue'
import get from 'lodash/get'
import i18n from './i18n'
import { ERROR_CODE } from '@/core/constants/ErrorCode'
import router from '@/router'
import { store } from '@/main'

export const apiErrorHandler = (error: any): any => {
  const code = get(error, 'response.data.code', null)
  let message = ''
  switch (error.response.status) {
    case 500:
      message = i18n.tc('notification.server_error')
      break
    case 400:
      if (code == ERROR_CODE.INVITATION_TOKEN_INVALID) {
        break
      }
      break
    case 403:
    case 404:
      if (
        code === ERROR_CODE.NO_ASSIGNABLE_TASK ||
        code === ERROR_CODE.NUMBER_OF_TASK_FOR_UNIT_IS_FULLFILLED ||
        code === ERROR_CODE.TASK_NOT_ASSIGNABLE
      ) {
        break
      }
      message = i18n.tc('notification.forbidden_error')
      break
    default:
      // error should be handle manually
      message = i18n.tc('notification.common_error')
      break
  }

  switch (code) {
    case ERROR_CODE.CANNOT_REVERT_REJECTION:
      message = i18n.tc('notification.cancel_rejection_error')
      break
    case ERROR_CODE.EMAIL_ALREADY_EXISTS:
      message = i18n.tc('notification.email_alredy_exists')
      break
    case ERROR_CODE.RESULT_ALREADY_SOLVED:
      message = i18n.tc('notification.result_already_solved_error')
      break
    case ERROR_CODE.PLATFORM_RESOURCE_NOT_FOUND:
      message = i18n.tc('notification.platform_resource_not_found_error')
      break
    case ERROR_CODE.PLATFORM_ACCESS_TOKEN_IS_EXPIRED:
      message = i18n.tc('notification.platform_access_token_is_expired')
      store.dispatch('logOut')
      router.replace({ name: 'login' })
      break
    case ERROR_CODE.IMPORT_TASK_IN_PROGRESS_EXISTS:
      message = i18n.tc('notification.import_task_in_progress_exists')
      break
    case ERROR_CODE.RESULT_UPDATE_STATUS_NOT_ACCEPTABLE:
      message = i18n.tc('notification.result_update_status_not_acceptable')
      break
    case ERROR_CODE.AUTHORIZATION_ONLY_ANNOTATION_MANAGER:
      message = i18n.tc('notification.authorization_only_annotation_manager')
      break
    case ERROR_CODE.PROJECT_INVALID_STATUS_TO_EXECUTE:
      message = i18n.tc('notification.project_invalid_status_to_execute')
      break
    case ERROR_CODE.CREDENTIAL_NOT_VERIFIED:
      message = i18n.tc('notification.credential_not_verified')
      break
    case ERROR_CODE.TASK_SOURCE_NOT_SUPPORT_SOURCE_URI:
      message = i18n.tc('notification.task_source_not_support_source_uri')
      break
    default:
      break
  }

  if (message) {
    Vue.prototype.$toast({
      type: 'error',
      text: message
    })
  }
}

import Organization from './Organization'
import { httpClient } from '../common/HttpClient'
import Role from './Role'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'
import { Invitation } from '@/core/models/Invitation'

export class UserOrganization extends Organization {
  static async listUserOrganizations(): Promise<UserOrganization[]> {
    const response = await httpClient.get<UserOrganization[]>('/api/v1/organizations')
    return response.map(d => UserOrganization.mapObject(d))
  }

  static mapObject(d: Partial<UserOrganization>) {
    return new UserOrganization(d)
  }
  role: Role

  private constructor(data: any) {
    super(data.id, data.type, data.name, data.platform_id)
    this.role = Role.getRoleById(data.role)
  }
  getAnnotators(): Promise<Annotator[]> {
    return httpClient.get(`/api/v1/organizations/${this.id}/users`).then((a: any) => a.map(Annotator.mapObject))
  }
  getBPOs(): Promise<BPO[]> {
    return httpClient.get(`/api/v1/organizations/${this.id}/units`).then((r: any) => r.map(BPO.mapObject))
  }
  getAnnotator(userID: number): Promise<Annotator> {
    return httpClient.get(`/api/v1/organizations/${this.id}/users/${userID}`).then((a: any) => Annotator.mapObject(a))
  }
  getInvitingMembers(): Promise<Invitation[]> {
    return httpClient
      .get(`/api/v1/organizations/${this.id}/invitations`)
      .then((d: any) => d.data.map(Invitation.mapObject))
  }
}

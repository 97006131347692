export class LimitOffsetPaging {
  currentPage: number
  perPage: number
  count = 0

  constructor(currentPage = 0, perPage = 10) {
    this.currentPage = currentPage
    this.perPage = perPage
  }

  get offset(): number {
    return this.currentPage * this.perPage
  }

  get maxPage(): number {
    if (this.count === 0 || this.perPage === 0) {
      return 0
    }

    const result = Math.floor(this.count / this.perPage)
    return this.count % this.perPage === 0 ? result - 1 : result
  }

  prev(): boolean {
    if (this.currentPage === 0) {
      return false
    }
    this.currentPage--
    return true
  }

  next(): boolean {
    if (this.currentPage >= this.maxPage) {
      return false
    }
    this.currentPage++
    return true
  }
}

import { ActionTree } from 'vuex'
import { IMessageState } from './index'
import { IRootState } from '@/store'
import { queries, operation } from '@/firebase'
import Notification from '@/core/models/Notification'

export const actions: ActionTree<IMessageState, IRootState> = {
  async getAnnouncements({ commit, rootState }, displayCount: number) {
    const data = await queries.announcements(displayCount).get()
    const an = data.docs.map(d => d.data())
    commit('setAnnouncements', an)
    if (an.length) {
      operation.updateLastReadAnnouncment(rootState.logInUser!, an[0].publishTime)
    }
  },
  async getNotifications({ commit, rootState }, displayCount: number) {
    const data = await queries
      .userNotifications(rootState.logInUser!, rootState.currentOrganizationID!, displayCount)
      .get()
    const notifications = await Promise.all(
      data.docs.map(d => {
        return d.data()
      })
    )
    const notificationObjectList = notifications.map(noti => new Notification(noti))
    commit('setNotifications', notificationObjectList)
    operation.readNotification(rootState.logInUser!, rootState.currentOrganizationID!)
  },
  async getAssignedWorks({ commit, rootState }) {
    commit('setCurrentAssignWorks', null)
    const assignedWorks = await rootState.logInUser!.getAssignedWork(rootState.currentOrganizationID!)
    commit(
      'setCurrentAssignWorks',
      assignedWorks.map(item => item.id)
    )
  }
}

export class TemplatePermissionScope {
  static readonly Public = new TemplatePermissionScope(1, 'Public')
  static readonly Private = new TemplatePermissionScope(2, 'Private')

  static readonly SCOPE = [TemplatePermissionScope.Public, TemplatePermissionScope.Private]

  static getScopeById(id: number) {
    const status = TemplatePermissionScope.SCOPE.find(r => r.id === id)
    return status || new TemplatePermissionScope(id, 'Unknown')
  }

  private constructor(public id: number, public scope: string) {}
}

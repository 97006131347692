import { TemplateOptions } from '@abeja-inc/annotation-js-sdk'
import Template from '@/core/models/Template'
import { Work } from './Work'
import Label from './annotation/Label'
import { httpClient } from '../common/HttpClient'
import Annotator from '@/core/models/Annotator'
import IAssignable from '@/core/models/IAssignable'
import {
  WorkBlockAnnotatorPerformance,
  WorkBlockTaskPerformance,
  WorkBlockTaskSummary
} from '@/core/models/WorkBlockPerformance'
import { formatEndDate, formatStartDate } from '@/core/helpers/requestHelper'
import { SourceURI } from '@/core/models/SourceURI'

type WorkCountResponse = {
  active_count: number
  total_count: number
}

export class WorkBlock {
  static mapObject(data: any, workCount?: WorkCountResponse): WorkBlock {
    const workBlock = new WorkBlock({
      id: data.id,
      projectID: data.project_id,
      sourceType: data.source_type,
      annotationType: data.annotation_type,
      name: data.name,
      labelMaster: data.label_master,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      templateOptions: data.template_options,
      workCount: workCount?.total_count || 0,
      activeWorkCount: workCount?.active_count || 0
    })

    if (data.works) {
      workBlock.works = data.works.map((workData: any) => {
        const workItem = Work.mapObject(workData)
        workItem.project = data.project
        return workItem
      })
    }

    if (data.template) {
      workBlock.template = Template.mapObject(data.template)
    }

    if (data.base_source_uri) {
      workBlock.baseSourceURI = new SourceURI(data.base_source_uri)
    }

    return workBlock
  }
  id = 0
  projectID?: number
  template?: Template
  sourceType = 0
  annotationType = 0
  name = ''
  labelMaster: any
  workCount = 0
  activeWorkCount = 0
  taskSummary?: WorkBlockTaskSummary
  works: Work[] = []
  templateOptions: TemplateOptions = {}
  baseSourceURI?: SourceURI
  createdAt?: Date
  updatedAt?: Date

  static async getWorkBlock(ctx: { orgId: number; projectId: number; workBlockId: number }): Promise<WorkBlock | null> {
    const response = await httpClient.get(
      `/api/v1/units/${ctx.orgId}/projects/${ctx.projectId}/blocks/${ctx.workBlockId}`
    )
    const workCount: WorkCountResponse = await httpClient.get(
      `/api/v1/units/${ctx.orgId}/projects/${ctx.projectId}/blocks/${ctx.workBlockId}/work_count`
    )
    return WorkBlock.mapObject(response, workCount)
  }

  constructor(init: Partial<WorkBlock>) {
    Object.assign(this, init)
  }
  findLabel(labelID: number, categoryID: number): Label {
    const category = this.labelMaster.find((item: any) => item.id === categoryID)
    if (category) {
      const searchLabel = category.labels.find((l: any) => l.id === labelID)
      if (searchLabel) {
        return new Label({
          id: labelID,
          categoryID,
          color: searchLabel.color,
          name: searchLabel.name
        })
      }
    }
    return new Label({ id: labelID, categoryID })
  }
  async getDefaultAssignedAnnotator(clientOrganizationId: number): Promise<Annotator[]> {
    return httpClient
      .get(`/api/v1/units/${clientOrganizationId}/projects/${this.projectID}/blocks/${this.id}/default_users`)
      .then((a: any) => (a || []).map(Annotator.mapObject))
  }

  async assignDefaultAnnotator(clientOrganizationId: number, assignable: IAssignable[]): Promise<IAssignable[]> {
    return httpClient
      .put(`/api/v1/units/${clientOrganizationId}/projects/${this.projectID}/blocks/${this.id}/default_users`, {
        users: assignable.map(a => a.id)
      })
      .then((a: any) => (a || []).map(Annotator.mapObject))
  }

  async getTaskSummary(clientOrganizationId: number): Promise<WorkBlockTaskSummary> {
    const taskSummary = await httpClient.get(
      `/api/v1/units/${clientOrganizationId}/projects/${this.projectID}/blocks/${this.id}/task_summary`
    )
    return new WorkBlockTaskSummary(true, taskSummary)
  }

  async getPerformanceByRange(clientOrganizationId: number, range: { start: Date; end: Date }) {
    const param = `start=${formatStartDate(range.start)}&end=${formatEndDate(range.end)}`
    const taskPerformance = await httpClient
      .get(`/api/v1/units/${clientOrganizationId}/projects/${this.projectID}/blocks/${this.id}/performance?${param}`)
      .then((data: any) => {
        return data.map((d: any) => new WorkBlockTaskPerformance(d))
      })
    const annotatorPerformance = await httpClient
      .get(
        `/api/v1/units/${clientOrganizationId}/projects/${this.projectID}/blocks/${this.id}/user_performance?${param}`
      )
      .then((data: any) => {
        return data.map((p: any) => new WorkBlockAnnotatorPerformance(p))
      })
    return {
      range: range,
      taskPerformance,
      annotatorPerformance
    }
  }

  get finishedWorkCount() {
    return this.workCount - this.activeWorkCount
  }
}

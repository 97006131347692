import { ActionTree } from 'vuex'
import { IProjectState } from './index'
import { IRootState } from '@/store/'

import IAssignable from '@/core/models/IAssignable'
import Project from '@/core/models/Project'
import ProjectStatus from '@/core/models/ProjectStatus'
import { ability } from '@/ability'
import Template from '../../../core/models/Template'
import Assignments from '@/core/models/Assignments'
import { WorkBlock } from '@/core/models/WorkBlock'

export const actions: ActionTree<IProjectState, IRootState> = {
  async getCurrentProject({ rootState, commit, dispatch }, projectId) {
    commit('setCurrentProject', null)
    const project = await Project.getProject(rootState.currentOrganizationID!, projectId)
    commit('setCurrentProject', project)
    await dispatch('getDefaultAssign', project)
  },

  async getDefaultAssign({ rootState, commit }, project: Project) {
    if (rootState.currentOrganizationID === project.ownerOrganizationID) {
      const defaultAssignment = await project.firstWorkBlock?.getDefaultAssignedAnnotator(
        rootState.currentOrganizationID!
      )
      commit('updateAssignedAnnotatorInCurrentProject', defaultAssignment)
    }
  },

  async getAssignableAnnotator({ rootGetters, commit, state }) {
    const assignableAnnotators: IAssignable[] = await rootGetters.currentOrganization.getAnnotators()
    commit('setAssignableAnnotators', assignableAnnotators)

    if (ability.can('list', 'assigned_bpo')) {
      const assignableBPOs: IAssignable[] = await rootGetters.currentOrganization.getBPOs()
      commit('setAssignableBPOs', assignableBPOs)
    }
  },

  async createProject({ rootState, dispatch, commit }, payload: { newProject: Project }) {
    if (rootState.currentOrganizationID != null) {
      const savedProject = await Project.createProject(rootState.currentOrganizationID, payload.newProject)
      commit('setCurrentProject', savedProject)
      await dispatch('saveDefaultAssignments', payload.newProject.defaultAssignments)
      // dispatch('getAssignedWorks') // TODO: change to default assigned?
      return savedProject
    }
  },

  async saveDefaultAssignments({ rootState, commit, state }, assigns: Assignments) {
    const currentWorkBlock: WorkBlock = state.currentProject!.firstWorkBlock!

    const defaultAssignment: IAssignable[] = await currentWorkBlock.assignDefaultAnnotator(
      rootState.currentOrganizationID!,
      assigns.allAnnotators
    )

    commit('updateAssignedAnnotatorInCurrentProject', defaultAssignment)
  },

  async updateProject({ commit, dispatch }, project: Project) {
    const updatedProject = await Project.updateProject(project)
    await commit('setCurrentProject', updatedProject)
    await dispatch('saveDefaultAssignments', project.defaultAssignments)
  },

  async updateProjectStatus({ commit, state }, params: { newStatus: ProjectStatus }) {
    const updatedProject = await state.currentProject!.updateProjectStatus(params.newStatus)
    commit('updateProjectStatus', updatedProject.status)
  },

  async getTemplateList({ commit, rootState }) {
    const templates = await Template.getAllTemplates(rootState.currentOrganizationID!)
    commit('setTemplates', templates)
  }
}

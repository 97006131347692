export default class Role {
  static readonly SuperAdmin = new Role(-1, 'superAdmin')
  static readonly OrganizationAdmin = new Role(1, 'organizationAdmin')
  static readonly ProjectManager = new Role(2, 'projectManager')
  static readonly AnnotatorManager = new Role(3, 'annotatorManager')
  static readonly Annotator = new Role(4, 'annotator')

  static readonly ROLES = [
    Role.SuperAdmin,
    Role.OrganizationAdmin,
    Role.ProjectManager,
    Role.AnnotatorManager,
    Role.Annotator
  ]
  static getRoleById(id: number) {
    const role = Role.ROLES.find(r => r.id === id)
    return role || new Role(id, 'Unknown')
  }

  equals(role: Role) {
    return this.id === role.id
  }

  canManage(targetRole: Role): boolean {
    return Role.ROLES.indexOf(targetRole) >= Role.ROLES.indexOf(this)
  }

  private constructor(public id: number, public name: string) {}
}

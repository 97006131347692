<template>
  <modal name="confirm-modal" classes="modal-content" height="auto" :width="420">
    <div>
      <div class="modal-header" v-if="title">
        <div class="modal-title">
          {{ title }}
        </div>
        <button type="button" class="btn btn-toolbar btn-toolbar-sm" @click="hide">
          <i class="fas fa-times" />
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-text">
          {{ text }}
        </div>
        <div class="mt-5 d-flex flex-row-reverse">
          <button class="btn btn-primary btn-lg font-weight-bold" @click="confirm">
            {{ confirmText }}
          </button>
          <button class="btn btn-link btn-lg font-weight-bold" @click="hide">
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/plugins/confirm'

export default {
  nam: 'ConfirmModal',
  data() {
    return {
      text: 'Are you sure?',
      title: '',
      confirmText: 'Yes, do the thing',
      cancelText: 'Cancel',
      onConfirm: {}
    }
  },
  beforeMount() {
    Modal.EventBus.$on('show', params => {
      this.show(params)
    })
  },
  methods: {
    show(params) {
      this.$modal.show('confirm-modal')
      this.title = params.title || ''
      this.text = params.text || this.$t('notification.are_you_sure')
      this.onConfirm = params.onConfirm
      this.confirmText = params.confirmText || this.$t('confirmation.areYouSure')
      this.cancelText = params.cancelText || this.$t('common.cancel')
    },
    hide() {
      this.$modal.hide('confirm-modal')
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm()
        this.hide()
      } else {
        this.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-body-text {
  font-size: 1.5rem;
  font-weight: 600;
}
</style>

import { ExportEventStatus } from './ExportEventStatus'
import User from './User'
import { httpClient } from '../common/HttpClient'
import axios from 'axios'
import { SourceURI } from './SourceURI'
import { IExportEvent } from '../interfaces/IExportEvent'

const CONTENT_TYPE: any = {
  json: {
    header: 'application/json',
    format: (data: any) => {
      return JSON.stringify(data, null, 4)
    }
  }
}
export default class ExportEvent implements IExportEvent {
  static mapObject(data: any): ExportEvent {
    const exportEvent = new ExportEvent({
      id: data.id,
      projectID: data.project_id,
      artifactURI: data.artifact_uri,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      finishedAt: data.finished_at
    })
    exportEvent.status = ExportEventStatus.getStatusById(data.status)
    if (data.user) {
      exportEvent.user = new User(data.user)
    }
    if (data.output_uri) {
      exportEvent.outputURI = new SourceURI(data.output_uri)
    }
    return exportEvent
  }
  id = 0
  createdAt?: Date
  updatedAt?: Date
  finishedAt?: Date
  projectID?: number
  artifactURI = ''
  outputURI?: SourceURI
  user?: User
  format = 'json'
  status: ExportEventStatus = ExportEventStatus.Unknown
  constructor(init: Partial<ExportEvent>) {
    Object.assign(this, init)
  }

  async getDownloadURL(orgID: number): Promise<string> {
    const response: { url: string } = await httpClient.get(
      `/api/v1/organizations/${orgID}/projects/${this.projectID}/exports/${this.id}/download`
    )
    return response.url
  }

  async getFileContent(url: string): Promise<any> {
    const handler: any = CONTENT_TYPE[this.format] || CONTENT_TYPE.json
    const res = await axios.get(url, {
      headers: {},
      data: null
    })
    return handler.format(res.data)
  }

  getFormat(): string {
    return this.format
  }
}

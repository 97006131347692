export default class ResultStatus {
  static readonly InProgress = new ResultStatus(1, 'InProgress')
  static readonly InReview = new ResultStatus(2, 'InReview')
  static readonly Complete = new ResultStatus(3, 'Completed')
  static readonly Pending = new ResultStatus(4, 'Pending')
  static readonly Reject = new ResultStatus(5, 'Rejected')
  static readonly RejectResolved = new ResultStatus(6, 'RejectResolved')
  static readonly Discard = new ResultStatus(7, 'Discard')

  static readonly items: ResultStatus[] = [
    ResultStatus.InProgress,
    ResultStatus.InReview,
    ResultStatus.Complete,
    ResultStatus.Reject,
    ResultStatus.Pending,
    ResultStatus.RejectResolved,
    ResultStatus.Discard
  ]

  static readonly itemsForReviewer: ResultStatus[] = [
    ResultStatus.InReview,
    ResultStatus.Reject,
    ResultStatus.Complete,
    ResultStatus.Pending,
    ResultStatus.Discard
  ]

  static readonly itemsForSelfReview: ResultStatus[] = [
    // Temporary hide In Progress tab until fixed issue #650
    // ResultStatus.InProgress,
    ResultStatus.InReview,
    ResultStatus.Reject
  ]

  static readonly itemsForNoNeedReview: ResultStatus[] = [
    // reviewRequired: false のワーク用
    ResultStatus.InReview,
    ResultStatus.Pending,
    ResultStatus.Discard
  ]

  static getStatusById(id: number): ResultStatus | undefined {
    return ResultStatus.items.find(s => s.id === id)
  }

  private constructor(public readonly id: number, public readonly name: string) {}
}

import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueRouter from 'vue-router'

export const initSentry = (router: VueRouter) => {
  if (process.env.VUE_APP_SENTRY_URL) {
    Sentry.init({
      Vue,
      environment: process.env.VUE_APP_STAGE,
      dsn: process.env.VUE_APP_SENTRY_URL,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ]
    })
  }
}

export const setSentryUser = (id: number, email: string) => {
  Sentry.setUser({
    id: String(id),
    email
  })
}
export const setLogRocketSession = (sessionURL: string): void => {
  Sentry.configureScope(scope => {
    scope.setExtra('LogRocket sessionURL', sessionURL)
  })
}
export const captureException = (error: Error) => {
  Sentry.captureException(error)
}

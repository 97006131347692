import SourceURIScheme from '@/core/models/SourceURIScheme'
import { SourceURI } from '@/core/models/SourceURI'

export const AWSRegions: string[] = [
  'us-east-2',
  'us-east-1',
  'us-west-1',
  'us-west-2',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'af-south-1',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-north-1',
  'me-south-1',
  'sa-east-1'
]

export function mapFromBaseSourceURI(bucket?: SourceURI) {
  let setting: CloudStorageSetting = new HTTPConnect()
  if (bucket) {
    if (bucket.scheme.id === SourceURIScheme.S3.id) {
      setting = new S3Connection()
      setting.bucket = bucket
    } else if (bucket.scheme.id === SourceURIScheme.GS.id) {
      setting = new GCSConnection()
      setting.bucket = bucket
    }
  }
  return setting
}

export class HTTPConnect {
  readonly name = 'http'
  credential = undefined
  bucket = undefined
}
export class GCSConnection {
  readonly name = 'gcs'
  credential?: JSON
  bucket?: SourceURI = new SourceURI({
    scheme: SourceURIScheme.GS.id,
    uri_path: '',
    host: ''
  })
}

export class AWSCredential {
  aws_access_key_id?: string
  aws_secret_access_key?: string
  aws_region?: string
}

export class S3Connection {
  readonly name = 'aws_s3'
  credential?: AWSCredential
  bucket?: SourceURI = new SourceURI({
    scheme: SourceURIScheme.S3.id,
    uri_path: '',
    host: ''
  })
}

export type CloudStorageSetting = HTTPConnect | GCSConnection | S3Connection

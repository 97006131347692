export class ResultSummary {
  static mapObject(data: any): ResultSummary {
    const ins = new ResultSummary({
      completeCount: data.complete_count,
      inReviewCount: data.in_review_count,
      notYetCount: data.not_yet_count,
      pendingCount: data.pending_count,
      totalCount: data.total_count,
      inProgressCount: data.in_progress_count,
      rejectCount: data.reject_count,
      discardCount: data.discard_count
    })
    return ins
  }

  completeCount = 0
  inReviewCount = 0
  notYetCount = 0
  pendingCount = 0
  totalCount = 0
  inProgressCount = 0
  rejectCount = 0
  discardCount = 0

  private constructor(initData: Partial<ResultSummary>) {
    Object.assign(this, initData)
  }

  get processedCount() {
    // I do not know which is better we will fix later
    // return this.discardCount + this.completeCount
    return this.completeCount
  }
}

import { httpClient } from '@/core/common/HttpClient'
import IndexedDB from '@/core/common/IndexedDB'
import { SourceURI } from './SourceURI'
import * as SDK from '@abeja-inc/annotation-js-sdk'

type FileMetadata = { [key: string]: string }

export default class TaskSource {
  static mapObject(data: any): TaskSource {
    const resource = new TaskSource({
      id: data.id,
      sourceURI: new SourceURI(data.source_uri),
      taskID: data.task_id,
      workID: data.work_id
    })
    resource.fileName = data.filename
    return resource
  }

  public id = 0
  public taskID = 0
  public sourceURI?: SourceURI
  public workID = 0
  public fileURL?: string
  public fileName = ''
  public fileMetadata: FileMetadata = {}

  private constructor(init: Partial<TaskSource>) {
    Object.assign(this, init)
  }

  async getFile(force = false): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getFileURL(force).then(url => {
        const image = new Image()
        image.onload = () => resolve(url)
        image.onerror = reject
        image.src = url
      })
    })
  }

  async getFileURL(force = false): Promise<string> {
    if (!force && this.fileURL && this.fileMetadata) {
      return this.fileURL
    }
    if (!this.id || !this.taskID || !this.workID || !this.sourceURI) {
      throw new Error('Dont have enough data to get fileURL')
    }

    const cache = await IndexedDB.getCachedFileInfo(this.sourceURI!.uriPath)
    if (cache) {
      this.fileURL = cache.url
      this.fileMetadata = cache.metadata
      return this.fileURL
    }

    const response = await this.fetchPresignedFile()
    this.fileURL = response.url
    this.fileMetadata = response.metadata
    await IndexedDB.setCachedFileInfo(this.sourceURI!.uriPath, this.fileURL!, this.fileMetadata!)
    return this.fileURL!
  }

  private async fetchPresignedFile(): Promise<{ url: string; metadata: FileMetadata }> {
    const response: { url: string; metadata: FileMetadata | null } = await httpClient.get(
      `/api/v1/works/${this.workID}/tasks/${this.taskID}/sources/${this.id}`
    )
    if (!response.metadata) {
      return {
        url: response.url,
        metadata: {}
      }
    }
    const entries = Object.entries(response.metadata)
    const formattedEntries = entries.map(e => [e[0].replace(/x-abeja-.*meta-/, ''), e[1]])
    const metadata: FileMetadata = Object.fromEntries(formattedEntries)
    return {
      url: response.url,
      metadata
    }
  }

  toIframeFormat(): SDK.TaskSource {
    return <SDK.TaskSource>{
      fileName: this.fileName,
      fileURL: this.fileURL,
      fileMetadata: this.fileMetadata
    }
  }
}

import { UserInvitation } from './../core/models/UserInvitation'
import { ActionTree } from 'vuex'
import { IRootState } from '@/store/'
import { localStorage } from '../core/common/LocalStorage'
import LoginUser from '@/core/models/LoginUser'
import { UserOrganization } from '../core/models/UserOrganization'
import DatalakeChannel from '@/core/models/DatalakeChannel'
import { firestoreAction } from 'vuexfire'
import { auth, collections, docs, queries } from '@/firebase'
import { setUserInLog } from '@/config/logrocket'
import { setSentryUser } from '@/config/sentry'

export const actions: ActionTree<IRootState, IRootState> = {
  async logInWithEmailAndPassword({ commit }, { email, password }: { email: string; password: string }) {
    await auth.signInWithEmailAndPassword(email, password)
    const token = await auth.currentUser!.getIdToken()
    const sessionID = await LoginUser.getSessionID(token)
    await localStorage.saveAuthSession(sessionID)
  },
  async logInWithAbejaPlatform(): Promise<any> {
    return LoginUser.logInWithAbejaPlatform()
  },
  async loginWithGoogle(): Promise<any> {
    return LoginUser.logInWithGoogle()
  },

  async loginWithGithub(): Promise<any> {
    return LoginUser.logInWithGithub()
  },

  async openIDCallback({ commit }, { query, platform }: { query: string; platform: string }) {
    let loginToken
    if (platform === 'google') {
      loginToken = await LoginUser.googleCallback(query)
    } else if (platform === 'github') {
      loginToken = await LoginUser.githubCallback(query)
    } else {
      loginToken = await LoginUser.openIDCallback(query)
    }

    await auth.signInWithCustomToken(loginToken)
    const token = await auth.currentUser!.getIdToken()
    const sessionID = await LoginUser.getSessionID(token)
    await localStorage.saveAuthSession(sessionID)
  },

  async listUserOrganizations({ commit }): Promise<UserOrganization[]> {
    const userOrganizations = await UserOrganization.listUserOrganizations()
    commit('setOrganizations', userOrganizations)
    return userOrganizations
  },

  async checkCurrentOrganizations({ commit, state }) {
    if (state.userOrganizations.length === 0) {
      return
    }
    const idList = state.userOrganizations.map(org => org.id)
    let currentOrganizationId = Number(localStorage.getCurrentOrganization())
    if (!currentOrganizationId || !idList.includes(currentOrganizationId)) {
      currentOrganizationId = state.userOrganizations[0].id
    }
    commit('changeCurrentOrganizationId', currentOrganizationId)
  },

  getCurrentUser: firestoreAction(async ({ state, commit, bindFirestoreRef }) => {
    if (state.currentOrganizationID) {
      const user = await LoginUser.getCurrentUser(state.currentOrganizationID)
      commit('setAuthUser', user)
      const profile = docs.userProfile(user)
      bindFirestoreRef('userProfile', profile)
      const newNotifications = collections
        .userNotificationsCollection(user, state.currentOrganizationID!)
        .where('read', '==', false)
      bindFirestoreRef('newNotifications', newNotifications)
      const profileSp = await profile.get()
      const profileDoc = await profileSp.data()
      const newAnnouncements = queries.announcements(1)
      bindFirestoreRef('newAnnouncements', newAnnouncements)
      setUserInLog(user, state.currentOrganizationID)
      setSentryUser(user.id, user.email)
      return user
    }
    throw new Error('Organization ID must be set first')
  }),

  async logOut() {
    await LoginUser.logOut()
    auth.signOut()
    return localStorage.clearStorage()
  },

  async getDatalakeChannels({ state, commit }) {
    if (state.currentOrganizationID != null) {
      return DatalakeChannel.getAllChannels(state.currentOrganizationID).then(channels => {
        commit('setChannels', channels)
      })
    } else {
      return
    }
  },

  async createChannel({ rootState, commit }, newDatalakeChannel: DatalakeChannel) {
    const newChannel = await DatalakeChannel.createDatalakeChannel(rootState.currentOrganizationID!, newDatalakeChannel)
    commit('setChannels', [newChannel, ...rootState.datalakeChannels])
    return newChannel
  },
  async acceptInvitation({ commit }, { invitationToken, email, fullname, password }) {
    await UserInvitation.acceptInvitation({ invitationToken, fullname, password })
    await auth.signInWithEmailAndPassword(email, password)
    const token = await auth.currentUser!.getIdToken()
    const sessionID = await LoginUser.getSessionID(token)
    await localStorage.saveAuthSession(sessionID)
  },

  async joinOrganization(_, invitationToken) {
    return UserInvitation.joinOrganization(invitationToken)
  }
}

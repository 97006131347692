import { Feature } from '@/features/feature'
import store from './store'
import route from './route'
const feature: Feature = {
  name: 'work',
  route,
  module: store
}

export default feature

import User from './User'
import { ExportEventStatus } from './ExportEventStatus'
import { SourceURI } from './SourceURI'
import { IExportEvent } from '../interfaces/IExportEvent'

export class ExportDatasetEvent implements IExportEvent {
  static ABEJA_PLATFORM_DATASET_FORMAT = 'abeja_platform_dataset'
  static ABEJA_PLATFORM_DATASET_FORMAT_NAME = 'ABEJA Platform dataset'

  id = 0
  workBlockID = 0
  workID?: number
  datasetID = 0
  userID = 0
  user?: User
  outputURI?: SourceURI
  createdAt?: Date
  updatedAt?: Date
  finishedAt?: Date
  status: ExportEventStatus = ExportEventStatus.Unknown

  static mapObject(data: any): ExportDatasetEvent {
    const exportEvent = new ExportDatasetEvent({
      id: data.id,
      workBlockID: data.work_block_id,
      workID: data.work_id,
      datasetID: data.dataset_id,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      finishedAt: data.finished_at
    })
    exportEvent.status = ExportEventStatus.getStatusById(data.status)
    if (data.user) {
      exportEvent.user = new User(data.user)
    }
    if (data.output_uri) {
      exportEvent.outputURI = new SourceURI(data.output_uri)
    }
    return exportEvent
  }

  constructor(init: Partial<ExportDatasetEvent>) {
    Object.assign(this, init)
  }

  getFormat(): string {
    return ExportDatasetEvent.ABEJA_PLATFORM_DATASET_FORMAT_NAME
  }

  get datasetURL(): string {
    return `${process.env.VUE_APP_ABEJA_PF_URL}/datasets/${this.datasetID}`
  }
}

import Vue from 'vue'
import { extend, localize, configure, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, max, min, confirmed, regex, min_value, integer } from 'vee-validate/dist/rules'

import en from 'vee-validate/dist/locale/en.json'
import ja from 'vee-validate/dist/locale/ja.json'

extend('email', email)
extend('required', required)
extend('max', max)
extend('min', min)
extend('confirmed', confirmed)
extend('regex', regex)
extend('min_value', min_value)
extend('integer', integer)

localize({
  en,
  ja
})

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const changeValidationI18n = (newLang: string) => {
  localize(newLang)
}

export { changeValidationI18n }

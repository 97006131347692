import { Work } from '@/core/models/Work'
import { GetterTree } from 'vuex'
import { IProjectState } from '.'
import { IRootState } from '@/store/'
import { AssignableSelections } from '@/core/models/IAssignable'

export const getters: GetterTree<IProjectState, IRootState> = {
  assignableSelections(state): AssignableSelections {
    const annotators = state.assignableAnnotators.filter(a => a.isAnnotator)
    const managers = state.assignableAnnotators.filter(a => a.isAnnotatorManager)
    const bpos = state.assignableBPOs

    return { annotators, managers, bpos }
  },

  hasOwnedProject(state, _, rootState) {
    if (!state.currentProject) {
      return false
    }
    if (!rootState.currentOrganizationID) {
      return false
    }
    return state.currentProject.ownerOrganizationID === rootState.currentOrganizationID
  }
}

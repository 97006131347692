import IAssignable from '@/core/models/IAssignable'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'

export default class Assignments {
  public assignments: IAssignable[]

  constructor(assignments: IAssignable[] = []) {
    this.assignments = assignments
  }

  get length() {
    return this.assignments.length
  }

  get assignedCount() {
    return this.assignments.length - this.projectManagers.length - this.organizationAdmin.length
  }

  get allAnnotators() {
    return this.assignments.filter(assignable => assignable instanceof Annotator)
  }

  get annotators() {
    return this.assignments.filter(assignable => assignable instanceof Annotator && assignable.isAnnotator)
  }

  get projectManagers() {
    return this.assignments.filter(assignable => assignable instanceof Annotator && assignable.isProjectManager)
  }

  get annotationManagers() {
    return this.assignments.filter(assignable => assignable instanceof Annotator && assignable.isAnnotatorManager)
  }

  get organizationAdmin() {
    return this.assignments.filter(assignable => assignable instanceof Annotator && assignable.isOrganizationAdmin)
  }

  get bpos() {
    return this.assignments.filter(assignable => assignable instanceof BPO)
  }

  includes(assignable: IAssignable) {
    return this.assignments.some(a => a.equals(assignable))
  }

  add(assignable: IAssignable) {
    this.assignments.push(assignable)
  }

  remove(assignable: IAssignable) {
    this.assignments = this.assignments.filter(a => !a.equals(assignable))
  }

  clone() {
    return new Assignments([...this.assignments])
  }
}

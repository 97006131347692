import User from './User'
import { NullUser } from './User'
import CommentType from './CommentType'

export default class Notification {
  projectName = ''
  organizationId = 0
  projectId = 0
  workId = 0
  workBlockId = 0
  taskId = 0
  comment?: string
  commentType?: CommentType
  timestamp: Date = new Date()
  read = false
  from?: User

  constructor(data: any) {
    this.projectName = data.projectName
    this.organizationId = data.organizationId
    this.projectId = data.projectId
    this.workId = data.workId
    this.workBlockId = data.workBlockId
    this.taskId = data.taskId
    this.comment = data.comment
    this.timestamp = data.timestamp
    this.read = data.read
    this.from = new NullUser()
    if (data.from) {
      this.from = new User(data.from)
    }
    this.commentType = CommentType.getById(data.commentType)
  }
}

import Role from './core/models/Role'
import { Ability } from '@casl/ability'

export const ability = new Ability()

const defaultAbility = [{ subject: 'project', actions: ['list'] }]

// common rule
// 'list': get the list of object
// 'view' : see detail of an object
// 'update': update an object
// 'create': create an object
// 'delete': delete an object

ability.update(defaultAbility)
const getAbilitiesFor = (role: Role) => {
  switch (role.id) {
    case 1: // super admin
      return [
        { subject: 'datalake', actions: ['list'] },
        { subject: 'project', actions: ['list', 'create', 'update', 'delete', 'view'] },
        { subject: 'work', actions: ['list', 'create', 'update', 'delete', 'view'] },
        { subject: 'annotator', actions: ['list', 'create', 'update', 'delete'] },
        { subject: 'importtask', actions: ['list', 'create'] },
        { subject: 'exportresult', actions: ['list', 'create'] },
        { subject: 'csvupload', actions: ['list', 'create'] },
        { subject: 'assigned_annotators', actions: ['list', 'update'] },
        // { subject: 'assigned_bpo', actions: ['list', 'update'] },
        { subject: 'assigned_task', actions: ['list', 'update'] },
        // { subject: 'bpo', actions: ['list', 'update'] },
        { subject: 'result', actions: ['list', 'review'] },
        { subject: 'access_token', actions: ['list', 'create', 'delete'] },
        { subject: 'invitation', actions: ['list', 'create', 'delete'] }
      ]
    case 2: // project manager
      return [
        { subject: 'datalake', actions: ['list'] },
        { subject: 'project', actions: ['list', 'create', 'update', 'delete', 'view'] },
        { subject: 'work', actions: ['list', 'create', 'update', 'delete', 'view'] },
        { subject: 'annotator', actions: ['list', 'create', 'update', 'delete'] },
        { subject: 'importtask', actions: ['list', 'create'] },
        { subject: 'exportresult', actions: ['list', 'create'] },
        { subject: 'csvupload', actions: ['list', 'create'] },
        { subject: 'assigned_annotators', actions: ['list', 'update'] },
        // { subject: 'assigned_bpo', actions: ['list', 'update'] },
        { subject: 'assigned_task', actions: ['list', 'update'] },
        // { subject: 'bpo', actions: ['list', 'update'] },
        { subject: 'result', actions: ['list', 'review'] },
        { subject: 'access_token', actions: ['list', 'create', 'delete'] },
        { subject: 'invitation', actions: ['list', 'create', 'delete'] }
      ]
    case 3: // annotator manager
      return [
        { subject: 'project', actions: ['list', 'view'] },
        { subject: 'work', actions: ['list', 'view'] },
        { subject: 'annotator', actions: ['list', 'create', 'update', 'delete', 'view'] },
        { subject: 'assigned_annotators', actions: ['list', 'update'] },
        { subject: 'assigned_task', actions: ['list', 'update'] },
        { subject: 'result', actions: ['list', 'review'] },
        { subject: 'access_token', actions: ['list', 'create', 'delete'] },
        { subject: 'invitation', actions: ['list', 'create', 'delete'] }
      ]
    case 4: // annotator
      return [
        { subject: 'project', actions: ['list'] },
        { subject: 'work', actions: ['list'] },
        { subject: 'result', actions: ['list', 'self_review'] }
      ]
  }
}

export const updateAbility = (role: Role) => {
  const abilities = getAbilitiesFor(role)
  ability.update(abilities || [])
}

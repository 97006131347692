import { RouteConfig } from 'vue-router'
const route: RouteConfig[] = [
  {
    path: '/annotators',
    component: {
      render(c: any) {
        return c('router-view')
      }
    },
    meta: {
      breadcrumb: 'members'
    },
    children: [
      {
        path: '/',
        component: () => import('./pages/Annotators.vue'),
        meta: {
          resource: 'annotator'
        },
        children: [
          {
            path: 'annotators',
            name: 'annotators',
            component: () => import('./pages/Annotators/AnnotatorList.vue'),
            meta: {
              breadcrumb: 'annotator',
              gtm: 'Annotator List'
            }
          },
          {
            path: 'annotation_managers',
            name: 'annotatorManager',
            component: () => import('./pages/Annotators/AnnotatorManagerList.vue'),
            meta: {
              breadcrumb: 'annotatorManager',
              gtm: 'Annotator Manager List'
            }
          },
          {
            path: 'project_managers',
            name: 'projectManager',
            component: () => import('./pages/Annotators/ProjectManagerList.vue'),
            meta: {
              breadcrumb: 'projectManager',
              gtm: 'Project Manager List'
            }
          },
          {
            path: 'bpos',
            name: 'annotatorBPO',
            component: () => import('./pages/Annotators/BPOList.vue'),
            meta: {
              resource: 'bpo',
              action: 'list',
              breadcrumb: 'annotatorBPO',
              gtm: 'BPO List'
            }
          },
          {
            path: '',
            redirect: 'annotators'
          }
        ]
      },
      {
        path: 'invitation',
        name: 'invitingMembers',
        component: () => import('./pages/InvitingMembers.vue'),
        meta: {
          resource: 'invitation',
          action: 'list',
          breadcrumb: 'invitingMembers',
          gtm: 'Inviting Members'
        }
      },
      {
        path: ':id',
        name: 'annotatorDetail',
        component: () => import('./pages/AnnotatorDetail.vue'),
        meta: {
          breadcrumb: 'annotatorDetail',
          gtm: 'Annotator detail'
        }
      },
      {
        path: ':id/edit',
        name: 'annotatorEdit',
        component: () => import('./pages/AnnotatorEdit.vue'),
        meta: {
          breadcrumb: 'annotatorEdit',
          gtm: 'Edit annotator'
        }
      }
    ]
  },
  {
    path: '/personalAccessToken',
    name: 'annotatorPersonalAccessToken',
    component: () => import('./pages/AnnotatorPersonalAccessToken.vue'),
    meta: {
      breadcrumb: 'annotatorPersonalAccessToken',
      gtm: 'Personal Access Token'
    }
  }
]
export default route

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { IStorage, LocalStorage } from './LocalStorage'
import { apiErrorHandler } from '@/errorHandler'

export interface IHttpClient {
  baseURL: string
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>
}

const isHandlerEnabled = (config: any = {}) => {
  // eslint-disable-next-line
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true
}

export class HttpClient implements IHttpClient {
  baseURL: string

  private http: AxiosInstance
  private storage: IStorage
  private errorHandler: (error: any) => any

  constructor(storage: IStorage, baseURL: string, errHandler: (error: any) => any) {
    this.baseURL = baseURL
    this.storage = storage
    this.errorHandler = errHandler
    this.http = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this.http.interceptors.request.use(config => {
      const newConfig = { ...config }
      const authToken = this.storage.getAuthSession()
      if (authToken) {
        newConfig.headers = {
          ...config.headers,
          'X-Annotation-Token': authToken
        }
      }
      return newConfig
    })
    this.http.interceptors.response.use(
      response => response,
      error => this.onError(error)
    )
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.http.get(url, config)
    return response.data
  }

  public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.http.post(url, data, config)
    return response.data
  }

  public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.http.put(url, data, config)
    return response.data
  }

  public async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.http.patch(url, data, config)
    return response.data
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.http.delete(url, config)
    return response.data
  }

  onError(error: any) {
    if (isHandlerEnabled(error.config) && this.errorHandler) {
      this.errorHandler(error)
    }
    return Promise.reject(error)
  }
}

export const httpClient = new HttpClient(new LocalStorage(), process.env.VUE_APP_BASE_URL || '', apiErrorHandler)

import { ActionTree } from 'vuex'
import { firestoreAction } from 'vuexfire'
import get from 'lodash/get'

import { IWorkspaceState } from '@/features/workspace/store/index'
import { IRootState } from '@/store'
import Project from '@/core/models/Project'
import Task from '@/core/models/Task'
import ReviewStream from '@/core/models/ReviewStream'
import { dummyAction, docs } from '@/firebase'
import { ERROR_CODE } from '@/core/constants/ErrorCode'
import { Work } from '@/core/models/Work'

export const actions: ActionTree<IWorkspaceState, IRootState> = {
  bindProgress: firestoreAction(async ({ state, commit, bindFirestoreRef }, workId) => {
    bindFirestoreRef('taskProgress', docs.workProgress(workId))
  }),
  unbindProgress: firestoreAction(async ({ state, commit, unbindFirestoreRef }) => {
    unbindFirestoreRef('taskProgress')
  }),
  async selfAssign({ state, rootState }) {
    if (state.currentTask!.assignedUserId !== rootState.logInUser!.id) {
      return state.currentTask!.changeAssign(rootState.logInUser!.id)
    }
  },
  async getProject({ rootState, commit, dispatch }, projectID) {
    const project = await Project.getProject(rootState.currentOrganizationID!, projectID)
    commit('setCurrentProject', project)
    commit('setCurrentTemplate', project.firstWorkBlock!.template)
    return project
  },
  async getWork({ commit, dispatch }, workID) {
    const work = await Work.getCurrentWork(workID)
    commit('setCurrentWork', work)
    dispatch('updateWorkSummary', work)
    return work
  },
  async updateWorkSummary({ state, commit }, work: Work) {
    dummyAction.postSummary(work.id, work.summary!, work.goalPoint)
  },
  async getReviewStream({ rootState }, payload: { task: Task; resultId: number }) {
    const comments = await payload.task.getComments(rootState.logInUser!.email)
    const reviewStream = new ReviewStream(payload.task.id!, payload.resultId, rootState.logInUser!, comments)
    return reviewStream
  },
  async getNewWork(
    { commit, rootState, dispatch },
    {
      workID,
      taskID
    }: {
      workID: number
      taskID: number | null
    }
  ) {
    const work: Work = await dispatch('getWork', workID)
    let result
    try {
      result = await work.getNewTask(taskID)
    } catch (err) {
      const code = get(err, 'response.data.code', null)
      if (code === ERROR_CODE.NO_ASSIGNABLE_TASK || code === ERROR_CODE.NUMBER_OF_TASK_FOR_UNIT_IS_FULLFILLED) {
        work.taskFinished()
      }
      throw err
    }
    if (rootState.logInUser && result.userId === rootState.logInUser.id) {
      result.user = rootState.logInUser
    }
    commit('setCurrentTask', result.task)
    commit('setCurrentResult', result)
  },
  async submitWork({ state }, payload: { data: any; files?: any }) {
    if (state.currentResult) {
      await state.currentResult.submitResult(payload, state.currentTask!.previousResult)
    }
  },
  async pendingWork({ state, rootState }, comment: string) {
    if (state.currentResult) {
      await state.currentResult.submitPending(comment)
    }
  }
}

import { MutationTree } from 'vuex'
import { IRootState } from '@/store/'
import { localStorage } from '@/core/common/LocalStorage'
import i18n from '@/i18n'
import LoginUser from '../core/models/LoginUser'
import { UserOrganization } from '../core/models/UserOrganization'
import DatalakeChannel from '@/core/models/DatalakeChannel'
import { vuexfireMutations } from 'vuexfire'
import { changeValidationI18n } from '@/utils/validation'
import { auth } from '@/firebase'

export const mutations: MutationTree<IRootState> = {
  ...vuexfireMutations,
  setAuthUser(state, payload: LoginUser) {
    state.logInUser = payload
  },
  toggleVisibleSidebar(state) {
    state.sidebar = !state.sidebar
  },
  hideSidebar(state) {
    state.sidebar = false
  },
  setOrganizations(state, payload: UserOrganization[]) {
    state.userOrganizations = payload
  },
  changeCurrentOrganizationId(state, payload: number) {
    state.currentOrganizationID = payload
    localStorage.saveCurrentOrganization(String(payload))
  },
  changeLocale(state, payload: string) {
    i18n.locale = payload
    state.locale = payload
    changeValidationI18n(payload)
    localStorage.saveLocale(payload)
    auth.languageCode = payload
  },
  setChannels(state, payload: DatalakeChannel[]) {
    state.datalakeChannels = payload
  }
}

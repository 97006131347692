export default class AnnotationSummary {
  constructor(
    public readonly projectCount: number,
    public readonly reviewCount: number,
    public readonly completeCount: number,
    public readonly rejectCount: number,
    public readonly solvedRejectCount: number
  ) {}

  get totalCount(): number {
    // This calculation logic is also in the ProjectSummary, so please modify it together
    return this.reviewCount + this.completeCount + this.rejectCount + this.solvedRejectCount
  }

  get totalRejectCount(): number {
    return this.rejectCount + this.solvedRejectCount
  }

  get rejectRate(): number {
    const { totalCount, totalRejectCount } = this
    if (totalCount === 0 || totalRejectCount === 0) {
      return 0
    }
    const rate = (totalRejectCount / totalCount) * 100
    return Number(rate.toFixed(1))
  }
}

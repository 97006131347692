import Vue from 'vue'
import Vuex, { Module, StoreOptions } from 'vuex'
import { Feature } from '@/features/feature'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import LoginUser from '@/core/models/LoginUser'
import { UserOrganization } from '@/core/models/UserOrganization'
import DatalakeChannel from '@/core/models/DatalakeChannel'
import Notification from '@/core/models/Notification'

Vue.use(Vuex)

export interface IRootState {
  version: string
  sidebar: boolean
  locale: string
  logInUser: LoginUser | null
  userOrganizations: UserOrganization[]
  currentOrganizationID: number | null
  datalakeChannels: DatalakeChannel[]
  userProfile: {
    lastReadAnnounce?: { seconds: number }
  }
  newNotifications: Notification[]
  newAnnouncements: any[] // TODO: Define type
}

export default function(features: Feature[]) {
  const modules: { [key: string]: Module<any, any> } = {}
  features.forEach(f => {
    modules[f.name] = f.module
  })
  const state: IRootState = {
    version: process.env.VUE_APP_VERSION || '',
    logInUser: null,
    sidebar: true,
    locale: (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language,
    userOrganizations: [],
    currentOrganizationID: null,
    datalakeChannels: [],
    userProfile: {},
    newNotifications: [],
    newAnnouncements: []
  }
  const store: StoreOptions<IRootState> = {
    state,
    mutations,
    actions,
    getters,
    modules
  }

  return new Vuex.Store<IRootState>(store)
}

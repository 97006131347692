import { httpClient } from '../common/HttpClient'
import User from '@/core/models/User'
import Annotator from '@/core/models/Annotator'
import { Work } from './Work'

export default class LoginUser extends User {
  static async logInWithEmailAndPassword(email: string, password: string): Promise<any> {
    const response = httpClient.post('/oauth/', { email, password })
    return response
  }
  static async logInWithAbejaPlatform() {
    window.location.href = httpClient.baseURL + '/oauth/abeja_platform/login'
  }
  static async openIDCallback(params = {}): Promise<string> {
    const response: { login_token: string } = await httpClient.get('/oauth/abeja_platform/oauthcallback', {
      params
    })
    return response.login_token
  }
  static async logInWithGoogle() {
    window.location.href = httpClient.baseURL + '/oauth/google/login'
  }

  static async logInWithGithub() {
    console.log('logInWithGithub')
    window.location.href = httpClient.baseURL + '/oauth/github/login'
  }
  static async googleCallback(params = {}): Promise<string> {
    const response: { login_token: string } = await httpClient.get('/oauth/google/oauthcallback', {
      params
    })
    return response.login_token
  }
  static async githubCallback(params = {}): Promise<string> {
    const response: { login_token: string } = await httpClient.get('/oauth/github/oauthcallback', {
      params
    })
    return response.login_token
  }
  static async logOut() {
    const response = httpClient.delete('/oauth/')
    return response
  }
  static async getCurrentUser(currentOrganizationID: number): Promise<LoginUser> {
    const response = httpClient.get<LoginUser>(`api/v1/organizations/${currentOrganizationID}/me`)
    return response.then(r => LoginUser.mapObject(r))
  }

  static async getSessionID(token: string): Promise<string> {
    const response: any = await httpClient.post(
      '/auth/',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response.session_id
  }

  private static mapObject(response: any) {
    return new LoginUser(response)
  }

  private constructor(d: any) {
    super(d)
  }

  async getAssignedWork(currentOrganizationID: number): Promise<Work[]> {
    const response: any[] = await httpClient.get(`api/v1/organizations/${currentOrganizationID}/assigns/me`)
    return (response || []).map((item: any) => Work.mapObject(item.work))
  }

  async assignMeToWork(projectID: number, workID: number, unitID: number): Promise<Work> {
    const response: any = await httpClient.post(`api/v1/works/${workID}/units/${unitID}/assigns/me`)
    return Work.mapObject(response.work)
  }

  toAnnotator(): Annotator {
    return Annotator.mapObject({
      ...this,
      role: this.role.id
    })
  }
}

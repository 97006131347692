import cloneDeep from 'lodash/cloneDeep'

import { httpClient } from '@/core/common/HttpClient'
import Comment from '@/core/models/Comment'
import ResultStatus from '@/core/models/ResultStatus'
import ReviewStream from '@/core/models/ReviewStream'
import User from '@/core/models/User'
import Task from '@/core/models/Task'
import * as SDK from '@abeja-inc/annotation-js-sdk'

export interface ResultLabel {
  label_id: number
  category_id: number
}
export interface ResultItemSchema {
  labels: ResultLabel[]
  meta: any
}

export interface FileResponse {
  filename: string
  mime_type: string
  download_url: string
}

export async function fetchResultFiles(workId: number, taskId: number, resultId: number): Promise<FileResponse[]> {
  const path = `/api/v1/works/${workId}/tasks/${taskId}/results/${resultId}/files`
  return await httpClient.get<FileResponse[]>(path)
}

export function injectDownloadURLToMeta(information: ResultItemSchema[], files: FileResponse[]) {
  return information.map(info => {
    if (!info.meta || !info.meta.image) {
      return info
    }
    const splitPath = info.meta.image.split('/')
    const filename = splitPath[splitPath.length - 1]
    const file = files.find(file => file.mime_type.startsWith('image') && file.filename === filename)
    if (!file) {
      return info
    }
    return {
      ...info,
      meta: {
        ...info.meta,
        image: file.download_url
      }
    }
  })
}

export default class Result {
  static mapObject(data: any): Result {
    const result = new Result({
      id: data.id,
      status: ResultStatus.getStatusById(data.status),
      taskId: data.task_id,
      userId: data.user_id,
      workId: data.work_id,
      updateAt: new Date(data.updated_at),
      workedAt: new Date(data.worked_at),
      assignedUserId: data.assigned_user_id
    })
    if (data.user) {
      result.user = new User(data.user)
    }

    if (data.information) {
      result.information = data.information.map((item: any) => cloneDeep(item))
    }
    if (data.task) {
      result.task = Task.mapObject(data.task, data.histories)
      result.taskId = result.task.id
      result.workId = result.task.workId
      result.commentCount = result.task.commentCount
    }
    return result
  }

  id = 0
  status: ResultStatus | undefined
  userId = 0
  workId?: number
  user?: User
  assignedUserId = 0
  taskId = 0
  task?: Task
  updateAt?: Date
  workedAt?: Date
  information: ResultItemSchema[] = []
  resolvedResultID?: number
  commentCount = 0

  private constructor(data: Partial<Result>) {
    Object.assign(this, data)
  }

  submitComplete(comment: string) {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/complete`, {
        comment
      })
      .then(Result.mapObject)
  }

  submitReject(comment: string) {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/reject`, {
        comment
      })
      .then(Result.mapObject)
  }

  submitRedit() {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/reedit`, {})
      .then(Result.mapObject)
  }

  submitRevertReview() {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/revert`, {})
      .then(Result.mapObject)
  }

  submitResolvePending() {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/resolve_pending`, {})
      .then(Result.mapObject)
  }

  submitDiscard() {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/discard`, {})
      .then(Result.mapObject)
  }

  submitRevertDiscard() {
    return httpClient
      .put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}/revert_discard`, {})
      .then(Result.mapObject)
  }

  async postMessage(comment: string) {
    await httpClient.post(`/api/v1/works/${this.workId}/tasks/${this.taskId}/comments`, {
      comment
    })
  }

  async getReviewStream(annotator: User): Promise<ReviewStream> {
    const comments = await this.getComments(annotator.email)
    return new ReviewStream(this.id, this.taskId, annotator, comments)
  }

  async getComments(email: string): Promise<Comment[]> {
    const response = (await httpClient.get(`/api/v1/works/${this.workId}/tasks/${this.taskId}/comments`, {})) as any[]
    return response.map(d => {
      const mine = email === d.user.email
      return Comment.mapObject(d, mine)
    })
  }

  async submitResult(
    result: { data: any; files?: { file_name: string; file: Blob; json_path: string }[] },
    previousResult: Result | null = null
  ) {
    const formData = new FormData()
    const resultParam: any = {
      status: ResultStatus.InReview.id.toString(),
      information: result.data
    }
    if (previousResult) {
      resultParam.resolved_result_id = previousResult.id.toString()
    }
    formData.append('result', JSON.stringify(resultParam))
    if (result.files) {
      result.files.forEach(({ file_name, file, json_path }) => {
        formData.append(json_path, file, file_name)
      })
    }
    const path = `/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}`
    const headers = { 'Content-Type': 'multipart/form-data' }
    const response = await httpClient.put(path, formData, { headers })
    return response
  }

  submitPending(comment: any) {
    return httpClient.put(`/api/v1/works/${this.workId}/tasks/${this.taskId}/results/${this.id}`, {
      pending: true,
      comment
    })
  }

  get isInReview() {
    return this.status === ResultStatus.InReview
  }
  get isInProgress() {
    return this.status === ResultStatus.InProgress
  }
  get isReject() {
    return this.status === ResultStatus.Reject
  }
  get isRejectResolved() {
    return this.status === ResultStatus.RejectResolved
  }
  get isComplete() {
    return this.status === ResultStatus.Complete
  }
  get isPending() {
    return this.status === ResultStatus.Pending
  }
  get isDiscard() {
    return this.status === ResultStatus.Discard
  }
  get canRevert() {
    return this.status === ResultStatus.Reject || this.status === ResultStatus.Complete
  }

  get fileName() {
    if (!this.task) {
      return null
    }
    return this.task.taskSources[0].fileName
  }

  async sourceURL() {
    if (!this.task) {
      return null
    }
    const url = await this.task.getFileURL()
    return url
  }

  get canEdit() {
    if (!this.status) {
      return false
    }
    return [ResultStatus.InProgress, ResultStatus.InReview, ResultStatus.Reject].includes(this.status)
  }

  async fetchFiles(): Promise<FileResponse[]> {
    return fetchResultFiles(this.workId!, this.taskId, this.id)
  }

  async prepareInformationData(hasFile = true) {
    let information = cloneDeep(this.information)
    if (hasFile) {
      const files = await this.fetchFiles()
      information = await injectDownloadURLToMeta(information, files)
    }
    return information
  }

  async toReviewIframeFormat(hasFile = true): Promise<SDK.Result> {
    const fileURL = await this.sourceURL()!
    const information = await this.prepareInformationData(hasFile)
    const taskSources = <SDK.TaskSource[]>this.task?.taskSources.map(ts => ts.toIframeFormat()) || []

    return <SDK.Result>{
      taskId: this.taskId,
      id: this.id,
      fileURL,
      fileName: this.fileName,
      information: information,
      commentCount: this.commentCount,
      workedAt: this.workedAt?.toString(),
      status: this.status!.id,
      taskMetadata: this.task?.metadata,
      taskSources
    }
  }

  async toWorkspaceIframeFormat(hasFile = true) {
    await Promise.all(this.task!.taskSources.map(taskSource => taskSource.getFileURL()))
    let preloadResult
    if (this.information && this.information.length) {
      preloadResult = await this.prepareInformationData(hasFile)
    } else if (this.task!.previousResult) {
      preloadResult = await this.task!.previousResult.prepareInformationData(hasFile)
    } else if (this.task!.preInferences) {
      preloadResult = this.task!.preInferences
    }
    return {
      taskSources: this.task!.taskSources.map(ts => ts.toIframeFormat()),
      taskMetadata: this.task!.metadata,
      previousResult: preloadResult || [],
      isRejected: this.task!.isRejected,
      id: this.task!.id
    }
  }
}

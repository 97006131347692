import { GetterTree } from 'vuex'
import { IRootState } from '.'
import Organization from '@/core/models/Organization'

export const getters: GetterTree<IRootState, IRootState> = {
  currentOrganization(state): Organization | undefined {
    return state.userOrganizations.find(org => org.id === state.currentOrganizationID)
  },
  hasNewNotifications(state): boolean {
    return 0 < state.newNotifications.length
  },
  hasNewAnnouncements(state): boolean {
    if (!state.userProfile) {
      return false
    }
    const latest = state.userProfile.lastReadAnnounce
    const hasNews = 0 < state.newAnnouncements.length
    if (latest == null) {
      return hasNews
    } else if (hasNews) {
      const news = state.newAnnouncements[state.newAnnouncements.length - 1]
      return latest.seconds < news.publishTime.seconds
    } else {
      return false
    }
  }
}

import { RouteConfig } from 'vue-router'
const route: RouteConfig[] = <Array<RouteConfig>>[
  {
    path: '/works',
    component: {
      render(c: any) {
        return c('router-view')
      }
    },
    meta: {
      breadcrumb: 'work'
    },
    children: [
      {
        path: '',
        component: () => import('./pages/Works.vue'),
        name: 'work',
        meta: {
          resource: 'work',
          action: 'list',
          gtm: 'Work List'
        }
      },
      {
        path: 'new',
        name: 'workCreate',
        component: () => import('./pages/CreateWork.vue'),
        meta: {
          resource: 'work',
          action: 'create',
          breadcrumb: 'workCreate',
          gtm: 'Create Work'
        }
      },
      {
        path: ':id',
        component: () => import('./pages/WorkDetail.vue'),
        children: [
          {
            path: 'information',
            name: 'WorkDetailInformation',
            component: () => import('./pages/WorkDetail/WorkDetailInformation.vue'),
            meta: {
              resource: 'work',
              action: 'view',
              breadcrumb: 'workDetail',
              gtm: 'Work Information'
            }
          },
          {
            path: 'assignment',
            name: 'WorkDetailAssignments',
            component: () => import('./pages/WorkDetail/WorkDetailAssignments.vue'),
            meta: {
              resource: 'work',
              action: 'list',
              breadcrumb: 'workAssignment',
              gtm: 'Work Assignment'
            }
          },
          {
            path: 'import',
            name: 'WorkDetailImport',
            component: () => import('./pages/WorkDetail/WorkDetailImport.vue'),
            meta: {
              resource: 'work',
              action: 'list',
              breadcrumb: 'workImport',
              gtm: 'Work Import'
            }
          },
          {
            path: 'csv',
            name: 'WorkDetailCSV',
            component: () => import('../work/pages/WorkDetail/WorkDetailCSV.vue'),
            meta: {
              resource: 'csvupload',
              action: 'list',
              breadcrumb: 'workCSV',
              gtm: 'Work CSV Import'
            }
          },
          {
            path: 'cloud',
            name: 'WorkDetailCloudStorage',
            component: () => import('../work/pages/WorkDetail/WorkDetailCloudStorage.vue'),
            meta: {
              resource: 'work',
              action: 'list',
              breadcrumb: 'workImport',
              gtm: 'Work CloudStorage Import'
            }
          },
          {
            path: 'export',
            name: 'WorkDetailExport',
            component: () => import('./pages/WorkDetail/WorkDetailExport.vue'),
            meta: {
              resource: 'work',
              action: 'list',
              breadcrumb: 'workExport',
              gtm: 'Work Export'
            }
          },
          {
            path: '',
            redirect: 'information'
          }
        ]
      }
    ]
  }
]
export default route

import _Vue from 'vue'

const eventBus = new _Vue()

interface AlertOptions {
  type: 'primary' | 'success' | 'danger'
  scope: 'global' | 'page'
  title: string
  message: string
  dismiss?: boolean
  actions: {
    label: string
    handler: () => void
  }
}

export const EVENT_SHOW_ALERT = 'ShowAlert'
export const EVENT_HIDE_ALERT = 'HideAlert'

declare module 'vue/types/vue' {
  interface Vue {
    $alert: {
      show: (options: AlertOptions) => void
      hide: () => void
      handler: _Vue
    }
  }
}

export default {
  install(Vue: typeof _Vue) {
    Vue.prototype.$alert = {
      show: (options: AlertOptions) => {
        eventBus.$emit(EVENT_SHOW_ALERT, options)
      },
      hide: () => {
        eventBus.$emit(EVENT_HIDE_ALERT)
      },
      handler: eventBus
    }
  }
}

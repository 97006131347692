import Role from '@/core/models/Role'
import dayjs from 'dayjs'
import { httpClient } from '@/core/common/HttpClient'

type InvitationPayload = {
  created_at: string
  updated_at: string
  id: number
  email: string
  organization_id: number
  role: number
  invitation_token: string
  invited: boolean
  expired_at: string
}

export class Invitation {
  readonly id: number
  readonly organizationId: number
  readonly role: Role
  readonly email: string
  readonly date: Date
  readonly expiredAt?: Date

  constructor(payload: InvitationPayload) {
    this.id = payload.id
    this.organizationId = payload.organization_id
    this.role = Role.getRoleById(payload.role)
    this.email = payload.email
    this.date = new Date(payload.updated_at)
    this.expiredAt = new Date(payload.expired_at)
  }

  static mapObject(payload: InvitationPayload): Invitation {
    return new Invitation(payload)
  }

  isExpired(): boolean {
    return !this.expiredAt ? false : dayjs(this.expiredAt).diff(Date.now()) < 0
  }

  static createInvitation(organizationId: number, email: string, role: number): Promise<Invitation> {
    return httpClient
      .post(`/api/v1/organizations/${organizationId}/invitations`, {
        email,
        role
      })
      .then((result: any) => {
        return Invitation.mapObject(result?.invitation)
      })
  }
  async revoke() {
    return httpClient.delete(`/api/v1/organizations/${this.organizationId}/invitations/${this.id}`)
  }
  async resendMail(): Promise<Invitation> {
    return Invitation.createInvitation(this.organizationId, this.email, this.role.id)
  }
}

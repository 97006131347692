export default class TemplateIcons {
  constructor(private readonly hostURL: string) {}

  get icon24URL() {
    return this.hostURL + '/icon24.svg'
  }

  get icon35URL() {
    return this.hostURL + '/icon35.svg'
  }
}

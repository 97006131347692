import { actions } from './actions'
import { mutations } from './mutations'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'
import { getters } from './getters'
import AccessToken from '@/core/models/AccessToken'
import { Invitation } from '@/core/models/Invitation'

export interface IAnnotatorsState {
  assignableAnnotators: Annotator[]
  bpos: BPO[]
  accessTokens: AccessToken[]
  invitingMembers: Invitation[]
}

const state: IAnnotatorsState = {
  assignableAnnotators: [],
  bpos: [],
  accessTokens: [],
  invitingMembers: []
}

const project = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default project

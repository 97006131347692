import { httpClient } from '@/core/common/HttpClient'
import ProjectSummary, { TemplateInfo } from '@/core/models/ProjectSummary'
import TemplateIcons from '@/core/models/TemplateIcons'

type Counter = {
  template: TemplateInfo
  count: number
}
type TaskCountMapTemplate = Map<number, Counter>

export default class ProjectSummaryCollection {
  public data: ProjectSummary[]
  private _taskCountMap: TaskCountMapTemplate | null = null

  static async getStatistic(orgID: number | string, userID: number | string) {
    const path = `/api/v1/organizations/${orgID}/users/${userID}/statistic`
    const response: any = await httpClient.get(path)
    const data = response.map((dataItem: any) => {
      const templateInfo = new TemplateInfo(
        dataItem.template_id,
        dataItem.template_name,
        new TemplateIcons(dataItem.template_host_url)
      )
      // This calculation logic is also in the AnnotationSummary, so please modify it together
      const totalCount =
        dataItem.in_review_count + dataItem.complete_count + dataItem.reject_count + dataItem.solved_reject_count
      return new ProjectSummary(
        dataItem.project_name,
        templateInfo,
        totalCount,
        dataItem.complete_count,
        dataItem.reject_count,
        dataItem.project_id
      )
    })
    return new ProjectSummaryCollection(data)
  }

  constructor(data: ProjectSummary[]) {
    this.data = data
  }

  get taskCountsPerTemplate(): Counter[] {
    if (this._taskCountMap === null) {
      const map = new Map() as TaskCountMapTemplate
      this.data.forEach(it => {
        const counter = map.get(it.templateInfo.id) || { template: it.templateInfo, count: 0 }
        counter.count += it.totalCount
        map.set(counter.template.id, counter)
      })
      this._taskCountMap = map
    }
    return Array.from(this._taskCountMap.values())
  }
}

import { httpClient } from './../common/HttpClient'

export interface InvitationVerify {
  accountExist: boolean
  email: string
}

export class UserInvitation {
  static async verifyInvitation(invitationToken: string): Promise<InvitationVerify> {
    const response: any = await httpClient.get('/auth/invitations/verify', {
      params: {
        invitation_token: invitationToken
      }
    })
    return {
      accountExist: response.account_exist,
      email: response.email
    }
  }

  static async acceptInvitation(payload: {
    invitationToken: string
    fullname: string
    password: string
  }): Promise<any> {
    const response: { user_id: number; organization_id: number } = await httpClient.post(
      `/auth/invitations/signup?invitation_token=${payload.invitationToken}`,
      {
        name: payload.fullname,
        password: payload.password
      }
    )
    return response
  }

  static async joinOrganization(invitationToken: string) {
    const response: { user_id: number; organization_id: number } = await httpClient.post(
      `/auth/invitations/join?invitation_token=${invitationToken}`,
      {}
    )
    return response
  }
}

import { SourceURI } from '@/core/models/SourceURI'
import SourceURIScheme from '@/core/models/SourceURIScheme'
import { httpClient } from '@/core/common/HttpClient'
import { Work } from '@/core/models/Work'

export interface IWorkSourcePayload {
  source_uri: {
    scheme: number
    uri_path: string
    host: string
  }
  filename: string
  mime_type: string
  uploaded_at: string
}

export interface CSVDownloadPayload {
  download_url: string
  content_type: string
}

export interface DownloadResourceInfo {
  download_url: string
  content_type: string
  filename: string
}

export default class WorkSource {
  public sourceURI: SourceURI
  public filename: string
  public mimeType: string
  public uploadedAt: Date

  constructor(payload: IWorkSourcePayload) {
    this.sourceURI = new SourceURI(payload.source_uri)
    this.filename = payload.filename
    this.mimeType = payload.mime_type
    this.uploadedAt = new Date(payload.uploaded_at)
  }

  get uriPath(): string {
    return this.sourceURI.uriPath
  }

  async getDownloadInfo(work: Work): Promise<DownloadResourceInfo> {
    const { scheme, host, uriPath } = this.sourceURI
    if (scheme !== SourceURIScheme.GCS) {
      throw new Error('This is not downloadable resource')
    }
    const path = [
      '/api/v1/organizations',
      work.project?.ownerOrganizationId,
      'projects',
      work.project?.id,
      'blocks',
      work.workBlockID,
      'works',
      work.id,
      'sources/download'
    ].join('/')
    const payload = await httpClient.get<CSVDownloadPayload>(path, {
      params: {
        scheme: scheme.id,
        host,
        path: uriPath
      }
    })
    return { ...payload, filename: this.filename }
  }
}

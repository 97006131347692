import { ActionTree } from 'vuex'
import { IReviewState } from '@/features/review/store/index'
import { IRootState } from '@/store'
import Project from '@/core/models/Project'
import ReviewStream from '@/core/models/ReviewStream'
import ResultsSearchCondition from '@/core/models/ResultsSearchCondition'
import { Work } from '@/core/models/Work'

export const actions: ActionTree<IReviewState, IRootState> = {
  async getInitialData(
    { commit, rootState, getters },
    payload: { projectID: number; workID: number; canReview: boolean }
  ) {
    const [project, work] = await Promise.all([
      Project.getProject(rootState.currentOrganizationID!, payload.projectID),
      Work.getCurrentWork(payload.workID)
    ])
    if (rootState.currentOrganizationID) {
      if (payload.canReview) {
        const annotators = await work.getAssignedAnnotators(rootState.currentOrganizationID)
        commit('setAnnotators', annotators)
      }
      commit('setProject', project)
      commit('setCurrentWork', work)
    }
  },
  async reject({ commit, state }, result: { id: number; comment: string }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      const newResult = await targetResult.submitReject(result.comment)
      commit('updateResult', newResult)
    }
  },
  async complete({ commit, state }, result: { id: number; comment: string }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      const newResult = await targetResult.submitComplete(result.comment)
      commit('updateResult', newResult)
    }
  },
  async revert({ commit, state }, result: { id: number }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      const newResult = await targetResult.submitRevertReview()
      commit('updateResult', newResult)
    }
  },
  async discardResult({ commit, state }, result: { id: number }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      const newResult = await targetResult.submitDiscard()
      commit('updateResult', newResult)
    }
  },
  async revertDiscard({ commit, state }, result: { id: number }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      const newResult = await targetResult.submitRevertDiscard()
      commit('updateResult', newResult)
    }
  },
  async resolvePending({ commit, state }, result: { id: number }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      const newResult = await targetResult.submitResolvePending()
      commit('updateResult', newResult)
    }
  },
  async postMessage({ state, commit }, result: { id: number; comment: string }) {
    const targetResult = state.results.find(r => r.id === result.id)
    if (targetResult) {
      await targetResult.postMessage(result.comment)
      targetResult.commentCount += 1
      commit('updateResult', targetResult)
    }
  },
  async selectResult({ commit, state, rootState }, resultId: number) {
    const targetResult = state.results.find(r => r.id === resultId)
    if (targetResult) {
      const annotator = targetResult!.user
      const comments = await targetResult.getComments(rootState.logInUser!.email)
      const reviewStream = new ReviewStream(targetResult.taskId, targetResult.id, annotator, comments)
      commit('setSelectedReviewStream', reviewStream)
      commit('setSelectedResult', targetResult)
    }
  },
  async initSearchResult({ commit, state }, payload: ResultsSearchCondition) {
    // clear result
    commit('setResults', [])
    const resultPaginator = state.currentWork!.getResultPaginator(payload)
    const newResults = await resultPaginator.getNextPage()
    commit('setResults', newResults)
    commit('setPaginator', resultPaginator)
  },
  async loadNextResult({ commit, state }) {
    const newResults = await state.resultPaginator!.getNextPage()
    commit('appendResults', newResults)
  }
}

import { httpClient } from '@/core/common/HttpClient'
import TaskSource from './TaskSource'
import Comment from '@/core/models/Comment'
import Result, { ResultItemSchema } from './Result'
import { ERROR_CODE } from '@/core/constants/ErrorCode'
import { NULL_FILE_URL } from '@/core/constants/NullObject'

export default class Task {
  static mapObject(data: any, histories: any = null): Task {
    const task = new Task({
      id: data.id,
      status: data.status,
      workId: data.work_id,
      assignedUserId: data.assigned_user_id,
      preInferences: data.pre_inferences,
      commentCount: data.comment_count || 0,
      metadata: data.metadata || {}
    })
    if (histories && histories.length) {
      const sortedHistory = histories.sort((a: any, b: any) => (a.created_at < b.created_at ? 1 : -1))
      const latestHistory = Result.mapObject(Object.assign(sortedHistory[0], { work_id: data.work_id }))
      task.setPreviousResult(latestHistory)
    }
    if (data.task_sources) {
      task.taskSources = data.task_sources.map((resource: any) => {
        return TaskSource.mapObject(Object.assign(resource, { work_id: data.work_id }))
      })
    }
    return task
  }

  public id = 0
  public status?: number
  public workId = 0
  public assignedUserId?: number
  public taskSources: TaskSource[] = []
  public previousResult: Result | null = null
  public isRejected = false
  public preInferences: ResultItemSchema[] | null = null
  public commentCount = 0
  public metadata: { [s: string]: any } = {}

  private constructor(init: Partial<Task>) {
    Object.assign(this, init)
  }

  setPreviousResult(historyResult: Result) {
    this.previousResult = historyResult
    this.isRejected = historyResult.isReject || historyResult.isRejectResolved
  }

  async getComments(email: string) {
    const response = (await httpClient.get(`/api/v1/works/${this.workId}/tasks/${this.id}/comments`, {})) as any[]
    return response.map(d => {
      const mine = email === d.user.email
      return Comment.mapObject(d, mine)
    })
  }

  changeAssign(userID: number) {
    return httpClient.put(`/api/v1/works/${this.workId}/tasks/${this.id}/assigns`, {
      user_id: userID
    })
  }

  async getFileURL() {
    return this.taskSources[0].getFileURL().catch(err => {
      const code = err.response && err.response.data.code
      if (code === ERROR_CODE.PLATFORM_RESOURCE_NOT_FOUND) {
        return NULL_FILE_URL
      }
    })
  }
}

import Template from '@/core/models/Template'
import get from 'lodash/get'

import { WorkBlock } from './WorkBlock'
import { ProjectInfo, Work } from './Work'
import { httpClient } from '../common/HttpClient'
import { ProjectsSearchCondition } from './ProjectsSearchCondition'
import ProjectStatus from './ProjectStatus'
import { TemplateOptions } from '@abeja-inc/annotation-js-sdk'
import SearchOption from '../interfaces/SearchOption'
import { Paginator } from './CursorPaginator'
import Assignments from '@/core/models/Assignments'
import { CloudStorageSetting, mapFromBaseSourceURI } from '@/core/models/CloudStrageSetting'

const DUMMY_LABELS = [
  {
    id: 0,
    name: 'Category1',
    labels: [
      {
        id: 0,
        name: 'dog',
        color: '#ff879c'
      },
      {
        id: 1,
        name: 'cat',
        color: '#908eff'
      }
    ]
  },
  {
    id: 1,
    name: 'Category2',
    labels: [
      {
        id: 2,
        name: 'penguin',
        color: '#ffe355'
      },
      {
        id: 3,
        name: 'duck',
        color: '#52ff68'
      }
    ]
  }
]

export default class Project {
  get isNew(): boolean {
    return this.id == null
  }

  get labels(): string {
    return JSON.stringify(this.labelsObject, null, '\t')
  }

  set type(type: any) {}

  static mapObject(data: any): Project {
    const p = new Project()
    p.id = data.id
    p.name = data.name
    p.description = data.description
    p.ownerOrganizationID = data.owner_organization_id
    p.status = ProjectStatus.getById(data.status)

    if (data.work_blocks && data.work_blocks.length) {
      const projectInfo: ProjectInfo = {
        id: p.id!,
        ownerOrganizationId: p.ownerOrganizationID,
        name: p.name,
        status: p.status
      }
      const workBlocks = data.work_blocks.map((block: any) =>
        WorkBlock.mapObject(Object.assign(block, { project: projectInfo }))
      )
      p.workBlocks = workBlocks
      const firstBlock = p.workBlocks[0]
      p.template = firstBlock.template
      p.labelsObject = firstBlock.labelMaster
      p.templateOptions = firstBlock.templateOptions
      p.cloudStorage = mapFromBaseSourceURI(firstBlock.baseSourceURI)
    }
    return p
  }
  static async getProjects(
    orgId: number,
    searchCondition?: ProjectsSearchCondition
  ): Promise<{ count: number; results: Project[] }> {
    let url = `/api/v1/units/${orgId}/projects`
    if (searchCondition) {
      url += '?' + searchCondition.toQueryParameter()
    }
    return httpClient.get(url).then((response: any) => {
      const results = response.result.map((r: any) => Project.mapObject(r))
      return {
        count: response.count,
        results: results
      }
    })
  }

  // async getWorks(): Promise<Work> {
  //   let url = `/api/v1/works?project_id=${this.id}`
  // }

  static async getProject(orgId: number, projectId: number): Promise<Project> {
    const response = await httpClient.get(`/api/v1/units/${orgId}/projects/${projectId}`)
    const project = Project.mapObject(response)
    // Since there is a possibility that not all works will be returned to works in the future,
    // We are using the WorkBlock API to reinsert them.
    const firstWorkBlock = await WorkBlock.getWorkBlock({
      orgId,
      projectId,
      workBlockId: project.workBlocks[0].id
    })
    if (firstWorkBlock) {
      project.workBlocks = [firstWorkBlock]
    }
    return project
  }

  static async createProject(organizationID: number, newProject: Project): Promise<Project> {
    const { name, description, labelsObject, template, templateOptions, cloudStorage } = newProject
    if (template === null || template === undefined) {
      throw Error('Project type is not exist.')
    }

    // MEMO: distribute label categories to multiple works when supporting vertical works
    const labelCategories = labelsObject.map(category => category.id)

    const params = {
      name,
      description,
      credential: cloudStorage?.credential,
      credential_type: cloudStorage?.bucket?.scheme.id,
      work_blocks: [
        {
          /*
            MEMO: set project name as work block name for now
                  to enable users to specify the name without API changes
          */
          name,
          template_id: template.id,
          label_master: labelsObject,
          base_source_uri: cloudStorage?.bucket?.uriString,
          template_options: templateOptions || {}
        }
      ]
    } as any
    const response = await httpClient.post(`/api/v1/organizations/${organizationID}/projects`, params)
    return Project.mapObject(response)
  }

  static async updateProject(updateProject: Project): Promise<Project> {
    const cloudStorage = updateProject.cloudStorage
    const payload = {
      name: updateProject.name,
      description: updateProject.description,
      template_options: updateProject.templateOptions,
      ...(cloudStorage && cloudStorage?.credential
        ? { credential_type: cloudStorage.bucket?.scheme.id, credential: cloudStorage?.credential }
        : {})
    }
    const response = await httpClient.put(
      `/api/v1/organizations/${updateProject.ownerOrganizationID}/projects/${updateProject.id}`,
      payload
    )
    return Project.mapObject(response)
  }

  id?: number
  name = ''
  status: ProjectStatus = ProjectStatus.NotStarted
  description = ''
  ownerOrganizationID = 0
  kind = 0
  numberOfResults = 0
  createdAt?: Date
  updatedAt?: Date
  workBlocks: WorkBlock[] = []
  labelsObject: any[] = DUMMY_LABELS
  template?: Template
  templateOptions: TemplateOptions = {}
  defaultAssignments: Assignments = new Assignments()
  cloudStorage?: CloudStorageSetting

  get firstWorkBlock(): WorkBlock | undefined {
    return get(this, 'workBlocks[0]')
  }

  async updateProjectStatus(newStatus: ProjectStatus): Promise<Project> {
    const response = await httpClient.put(
      `/api/v1/organizations/${this.ownerOrganizationID}/projects/${this.id}/status`,
      {
        status: newStatus.id
      }
    )
    return Project.mapObject(response)
  }

  getProjectWorkPaginator(orgID: number, searchParams: SearchOption): Paginator<Work> {
    const baseURL = `/api/v1/organizations/${orgID}/projects/${this.id}/blocks/${this.firstWorkBlock!.id}/works`
    return new Paginator<Work>(baseURL, searchParams, Work.mapObject)
  }

  clone(): Project {
    return JSON.parse(JSON.stringify(this)) as Project
  }

  get templateName(): string {
    return this.template ? this.template.name : 'Unknown'
  }

  get isSelectableSchema(): boolean {
    return this.template ? this.template.isSchemaSelectable : false
  }

  equals(a: Project) {
    if (!a) {
      return false
    }
    return this.id === a.id
  }

  get hasAnyAssignments(): boolean {
    return this.defaultAssignments.assignedCount > 0
  }
}

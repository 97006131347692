import _Vue from 'vue'

interface ToastOptions {
  type: 'success' | 'error' | 'warning'
  text: string
  timer?: number
  showConfirmButton?: boolean
}

declare module 'vue/types/vue' {
  interface Vue {
    $toast: (options: ToastOptions) => void
  }
}

export default {
  install(Vue: typeof _Vue) {
    Vue.prototype.$toast = function(options: ToastOptions) {
      const { type, text, timer, showConfirmButton } = options
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: showConfirmButton || false,
        timer: timer || 5000,
        icon: type,
        text
      })
    }
  }
}

import Dexie from 'dexie'
import dayjs from 'dayjs'

export interface IPresignedUrl {
  key: string
  url: string
  metadata: any
  expires: number
}

class IndexedDB extends Dexie {
  presigned_urls: Dexie.Table<IPresignedUrl, number>
  constructor() {
    super('abeja_annotation')
    this.version(1).stores({
      presigned_urls: 'key, url, expires'
    })
    this.presigned_urls = this.table('presigned_urls')
    this.garbageCollection()
  }
  async getCachedFileInfo(key: string) {
    const collections = this.presigned_urls.where('key').equals(key)
    const cache = await collections.first()
    if (cache && cache.expires <= this.expireBaseTime) {
      console.debug('url cache expired', cache.expires)
      await collections.delete()
      return null
    } else {
      return cache
    }
  }
  async setCachedFileInfo(key: string, url: string, metadata: { [key: string]: string }) {
    const expires = new URL(url).searchParams.get('Expires')
    await this.presigned_urls.put({
      key,
      url,
      metadata,
      expires: Number(expires)
    })
  }
  get expireBaseTime(): number {
    // Expire cache five minutes earlier than real expires
    // FIXME The expire time is 1 hour. But token expired 45 min. It looks S3 issue.
    return dayjs()
      .add(30, 'minute')
      .unix()
  }
  private garbageCollection() {
    const expiredCache = this.presigned_urls.where('expires').below(this.expireBaseTime)
    expiredCache.delete().catch(e => {
      console.error('Failed garbage collection', e)
      // Rebuild table when error
      this.presigned_urls.delete(1).then(() => {
        this.presigned_urls = this.table('presigned_urls')
      })
    })
  }
}

const db = new IndexedDB()
export default db

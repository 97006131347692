import { ResultLabel } from '@/core/models/Result'

export default class Label implements ResultLabel {
  id = 0
  name = ''
  color?: string
  categoryID?: number

  constructor(init: Partial<Label>) {
    Object.assign(this, init)
  }

  toResultFormat() {
    return {
      label_id: this.id,
      category_id: this.categoryID
    }
  }
  get category_id(): number {
    return this.categoryID!
  }
  get label_id(): number {
    return this.id!
  }
}

import { RouteConfig } from 'vue-router'
const route: RouteConfig[] = [
  {
    path: '/messages',
    component: () => import('./pages/MessagePage.vue'),
    children: [
      {
        path: 'announcements',
        name: 'announcementList',
        component: () => import('./pages/Announcements.vue'),
        meta: {
          breadcrumb: 'announcementList',
          gtm: 'Announcement List'
        }
      },
      {
        path: 'notifications',
        name: 'notificationList',
        component: () => import('./pages/Notifications.vue'),
        meta: {
          breadcrumb: 'task_updates',
          gtm: 'Notification List'
        }
      },
      {
        path: '',
        redirect: 'announcements'
      }
    ]
  }
]
export default route

import store from 'store'

export interface IStorage {
  saveAuthSession(sessionId: string): any
  getAuthSession(): string
  removeAuthSession(): any
  saveCurrentOrganization(organizationID: string): any
  getCurrentOrganization(): string
  removeCurrentOrganization(): any
  saveLocale(locale: string): any
  getLocale(): any
  clearStorage(): any
}
export class LocalStorage implements IStorage {
  static readonly SESSION_ID = 'session_id'
  static readonly CURRENT_ORGANIZATION_ID = 'current_organization_id'
  static readonly LOCALE = 'locale'
  static readonly INVITATION_TOKEN = 'invitation_token'

  saveAuthSession(sessionId: string) {
    return this.set(LocalStorage.SESSION_ID, sessionId)
  }
  getAuthSession(): string {
    return this.get(LocalStorage.SESSION_ID)
  }
  removeAuthSession() {
    return this.remove(LocalStorage.SESSION_ID)
  }

  saveCurrentOrganization(organizationID: string) {
    return this.set(LocalStorage.CURRENT_ORGANIZATION_ID, organizationID)
  }
  getCurrentOrganization(): string {
    return this.get(LocalStorage.CURRENT_ORGANIZATION_ID)
  }
  removeCurrentOrganization() {
    return this.remove(LocalStorage.CURRENT_ORGANIZATION_ID)
  }

  saveLocale(locale: string) {
    return this.set(LocalStorage.LOCALE, locale)
  }

  getLocale() {
    return this.get(LocalStorage.LOCALE)
  }

  saveInvitationToken(token: string) {
    return this.set(LocalStorage.INVITATION_TOKEN, token)
  }

  getInvitationToken() {
    return this.get(LocalStorage.INVITATION_TOKEN)
  }

  removeInvitationToken() {
    return this.remove(LocalStorage.INVITATION_TOKEN)
  }

  clearStorage() {
    this.removeAuthSession()
    this.removeCurrentOrganization()
    this.removeInvitationToken()
  }

  get(key: string): any {
    return store.get(key) || ''
  }
  set(key: string, newValue: any) {
    store.set(key, newValue)
    return newValue === this.get(key)
  }
  remove(key: string) {
    return store.remove(key)
  }
}

export const localStorage = new LocalStorage()

import { IMessageState } from './index'
import Notification from '@/core/models/Notification'

export const mutations = {
  setNotifications(state: IMessageState, payload: Notification[]) {
    state.notifications = payload
  },
  setAnnouncements(state: IMessageState, payload: any) {
    state.announcements = payload
  },
  setCurrentAssignWorks(state: IMessageState, payload: number[]) {
    state.currentUserAssignedWorkIDs = payload
  }
}

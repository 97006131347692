import SourceURIScheme from '@/core/models/SourceURIScheme'
import DatalakeChannel from './DatalakeChannel'

interface SoureURIResponse {
  scheme: number
  uri_path: string
  host: string
}

export class SourceURI {
  scheme: SourceURIScheme
  uriPath: string
  host: string

  constructor(data: SoureURIResponse) {
    this.scheme = SourceURIScheme.getById(data.scheme)
    this.uriPath = data.uri_path
    this.host = data.host
  }

  get datalakeChannelId(): string {
    if (!this.scheme.equals(SourceURIScheme.DATALAKE)) {
      throw new Error(` ${this.scheme.name} is not datalake schema`)
    }
    return this.host
  }

  get schemeString(): string {
    return `${this.scheme.name.toLowerCase()}://`
  }

  get uriString(): string {
    return `${this.scheme.name.toLowerCase()}://${this.host}/${this.uriPath}`
  }

  get hostWithPath(): string {
    return `${this.host}/${this.uriPath}`
  }

  append(path: string): SourceURI {
    const newPath = `${this.uriPath}/${path}`.replace(/\/+\//g, '/').replace(/^\//g, '')
    return new SourceURI({
      scheme: this.scheme.id,
      host: this.host,
      uri_path: newPath
    })
  }

  getDatalakeChannelName(channels: DatalakeChannel[]): string {
    const channel = (channels || []).find(c => String(c.id) === String(this.datalakeChannelId))
    return channel ? channel.name : this.host
  }

  get toLinkURL() {
    if (this.scheme.id === SourceURIScheme.GS.id) {
      return `https://console.cloud.google.com/storage/browser/${this.hostWithPath}`
    } else if (this.scheme.id === SourceURIScheme.S3.id) {
      return `https://${this.host}.s3.amazonaws.com`
    } else {
      return ''
    }
  }
}

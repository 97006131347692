import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import { Module } from 'vuex'
import { IRootState } from '@/store'
import Notification from '@/core/models/Notification'

export interface IMessageState {
  announcements: []
  notifications: Notification[]
  currentUserAssignedWorkIDs: number[]
}

const state: IMessageState = {
  announcements: [],
  notifications: [],
  currentUserAssignedWorkIDs: []
}

const message: Module<IMessageState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default message

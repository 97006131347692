import axios from 'axios'
import Role from '@/core/models/Role'
import { httpClient } from '../common/HttpClient'

type ProviderId = 'password' | 'google.com' | 'github.com' | 'oidc.abeja-platform'

export default class User {
  get isNew(): boolean {
    return !this.id
  }
  id: number
  name: string
  email: string
  emailVerified = false
  photoURI: string
  role: Role
  newImage: File | null = null
  createdAt: Date
  providerId: ProviderId

  public constructor(d: any) {
    this.id = d.id
    this.name = d.name
    this.email = d.email
    this.emailVerified = d.email_verified
    this.role = Role.getRoleById(d.role)
    this.photoURI = d.photo_uri
    this.createdAt = new Date(d.created_at)
    this.providerId = d.provider_id
  }
  async uploadAvatar(avatar: any, options = {}): Promise<string> {
    const uploadURL = await this.getUploadAvatarURL()
    const response = await axios.put(uploadURL, avatar, options)
    if (response && response.data && response.data.url) {
      return response.data.url
    }
    return uploadURL.split('?')[0]
  }
  async getUploadAvatarURL(): Promise<string> {
    const response: any = await httpClient.get(`/api/v1/users/${this.id}/upload_avatar_url`)
    return response.url
  }

  get canBeEdit(): boolean {
    return this.isAnnotator || this.isAnnotatorManager || this.providerId !== 'oidc.abeja-platform'
  }
  get canEditProfile(): boolean {
    return this.providerId === 'password'
  }
  get isInHouseAccount(): boolean {
    return this.providerId === 'password'
  }

  get isAnnotator(): boolean {
    return this.role.equals(Role.Annotator)
  }

  get isAnnotatorManager(): boolean {
    return this.role.equals(Role.AnnotatorManager)
  }

  get isProjectManager(): boolean {
    return this.role.equals(Role.ProjectManager)
  }

  get isOrganizationAdmin(): boolean {
    return this.role.equals(Role.OrganizationAdmin)
  }
}

export class NullUser extends User {
  get isNull() {
    return true
  }
  public constructor() {
    super({
      id: 0,
      name: 'An user',
      email: 'unknow@abejainc.com',
      role: -1
    })
  }
}

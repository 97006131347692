import User from '@/core/models/User'
import CommentType from '@/core/models/CommentType'

export default class Comment {
  static mapObject(data: any, mine: boolean): Comment {
    const c = new Comment(
      mine,
      CommentType.getById(data.comment_type),
      data.comment,
      new User(data.user),
      new Date(data.created_at)
    )
    return c
  }
  protected constructor(
    public readonly mine: boolean,
    public readonly type: CommentType,
    public readonly comment: string,
    public readonly author: User,
    public readonly timestamp: Date
  ) {}
}

import { IReviewState } from '@/features/review/store/index'
import Result from '@/core/models/Result'
import ReviewStream from '@/core/models/ReviewStream'
import { Paginator } from '../../../core/models/CursorPaginator'
import { Work } from '@/core/models/Work'

export const mutations = {
  setProject(state: IReviewState, payload: any) {
    state.project = payload
  },
  setCurrentWork(state: IReviewState, payload: Work) {
    state.currentWork = payload
  },
  setAnnotators(state: IReviewState, payload: any) {
    state.annotators = payload
  },
  setResults(state: IReviewState, payload: any) {
    state.results = payload
  },
  appendResults(state: IReviewState, payload: Result[]) {
    state.results = state.results.concat(payload)
  },
  updateResult(state: IReviewState, payload: Result) {
    const index = state.results.findIndex(r => r.id === payload.id)
    state.results[index].status = payload.status
    state.results[index].commentCount = payload.commentCount
    state.results = [...state.results]
  },
  setSelectedReviewStream(state: IReviewState, payload: ReviewStream) {
    state.selectedReviewStream = payload
  },
  setSelectedResult(state: IReviewState, payload: Result) {
    state.selectedResult = payload
  },
  batchUpdateStatus(state: IReviewState, payload: any) {
    state.results = state.results.map(result => {
      if (payload.resultIDs.includes(result.id)) {
        result.status = payload.status
      }
      return result
    })
  },
  setPaginator(state: IReviewState, paginator: Paginator<Result>) {
    state.resultPaginator = paginator
  }
}

import { RouteConfig } from 'vue-router'
import { Module } from 'vuex'

import Project from '@/features/project'
import Review from '@/features/review'
import Workspace from '@/features/workspace'
import Annotators from '@/features/annotators'
import Message from '@/features/message'
import Work from '@/features/work'

export interface Feature {
  name: string
  route: RouteConfig[]
  module: Module<any, any>
}

const features: Feature[] = [Project, Review, Workspace, Annotators, Message, Work]
export default features

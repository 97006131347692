export class ExportEventStatus {
  static readonly Unstarted = new ExportEventStatus(1, 'Unstarted')
  static readonly InProgress = new ExportEventStatus(2, 'In progress')
  static readonly Failure = new ExportEventStatus(3, 'Failure')
  static readonly Completed = new ExportEventStatus(4, 'Completed')
  static readonly Unknown = new ExportEventStatus(0, 'Unknown')

  static readonly STATUS = [
    ExportEventStatus.Unstarted,
    ExportEventStatus.InProgress,
    ExportEventStatus.Failure,
    ExportEventStatus.Completed,
    ExportEventStatus.Unknown
  ]

  static getStatusById(id: number) {
    const status = ExportEventStatus.STATUS.find(r => r.id === id)
    return status || new ExportEventStatus(id, 'Unknown')
  }

  private constructor(public id: number, public status: string) {}
}

import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import { Feature } from './features/feature'
import { LocalStorage } from '@/core/common/LocalStorage'
import features from '@/features/feature'
Vue.use(Router)

const storage = new LocalStorage()

const alias: RouteConfig[] = [
  // This is top page
  {
    path: '/',
    redirect: '/projects'
  }
]

function createRouter(features: Feature[]): Router {
  const featureRoute = features.reduce((a: RouteConfig[], b: Feature) => a.concat(b.route), [])

  const allRoute: RouteConfig[] = [
    {
      path: '/login/oauthcallback',
      name: 'oauthcallback',
      meta: {
        noAuth: true
      },
      component: () => import(/* webpackChunkName: "annotation" */ './pages/OpenIDCallback.vue')
    },
    {
      path: '/invitation',
      name: 'invitation',
      meta: {
        noAuth: true
      },
      component: () => import(/* webpackChunkName: "invitation" */ './pages/AccountSetup.vue')
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import(/* webpackChunkName: "registration" */ './pages/Registration.vue'),
      meta: {
        noAuth: true,
        gtm: 'Registration'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ './pages/Login.vue'),
      meta: {
        noAuth: true,
        gtm: 'Login'
      }
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: () => import(/* webpackChunkName: "auth" */ './pages/ForgotPassword.vue'),
      meta: {
        noAuth: true,
        gtm: 'ForgotPassword'
      }
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: () => import(/* webpackChunkName: "auth" */ './pages/ResetPassword.vue'),
      meta: {
        noAuth: true,
        gtm: 'ResetPassword'
      }
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import(/* webpackChunkName: "annotation" */ './pages/UnauthorizedPage.vue'),
      meta: {
        noAuth: true,
        gtm: 'Unauthorized'
      }
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: "annotation" */ './pages/Home.vue'),
      children: featureRoute.concat(alias)
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "annotation" */ './pages/NotFoundPage.vue'),
      meta: {
        noAuth: true
      }
    }
  ]

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: allRoute
  })

  // Navigation Guard
  router.beforeEach((to, from, next) => {
    if (to.meta === undefined) {
      return
    }
    // Check login status. Anonimous user redirect to login page If no auth user come.
    if (!to.meta.noAuth && !storage.getAuthSession()) {
      router.replace('/login')
    } else {
      next()
    }
  })

  return router
}

const router = createRouter(features)
export default router

import { GetterTree } from 'vuex'
import { IAnnotatorsState } from '.'
import { IRootState } from '@/store'
import Annotator from '@/core/models/Annotator'

export const getters: GetterTree<IAnnotatorsState, IRootState> = {
  annotators(state): Annotator[] | undefined {
    return state.assignableAnnotators.filter(a => a.isAnnotator)
  },
  annotatorManagers(state): Annotator[] | undefined {
    return state.assignableAnnotators.filter(a => a.isAnnotatorManager)
  },
  projectManagers(state): Annotator[] | undefined {
    return state.assignableAnnotators.filter(a => a.isProjectManager)
  }
}

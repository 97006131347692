import { IAnnotatorsState } from '@/features/annotators/store/index'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'
import AccessToken from '@/core/models/AccessToken'
import { Invitation } from '@/core/models/Invitation'

export const mutations = {
  setAnnotators(state: IAnnotatorsState, payload: Annotator[]) {
    state.assignableAnnotators = payload
  },
  setBPOs(state: IAnnotatorsState, payload: BPO[]) {
    state.bpos = payload
  },
  removeOneAnnotator(state: IAnnotatorsState, deletedAnnotator: Annotator) {
    state.assignableAnnotators = state.assignableAnnotators.filter(a => a.id !== deletedAnnotator.id)
  },

  setAccessTokens(state: IAnnotatorsState, accessTokens: AccessToken[]) {
    state.accessTokens = accessTokens.sort(
      (a, b) =>
        // sort by createdAt as descending order
        b.createdAt.getTime() - a.createdAt.getTime()
    )
  },
  setInvitingMembers(state: IAnnotatorsState, invitingMembers: Invitation[]) {
    state.invitingMembers = invitingMembers
  }
}

import { ActionTree } from 'vuex'
import { IAnnotatorsState } from '@/features/annotators/store/index'
import { IRootState } from '@/store'
import Annotator from '@/core/models/Annotator'
import AccessToken from '@/core/models/AccessToken'
import { Invitation } from '@/core/models/Invitation'
import Role from '@/core/models/Role'

export const actions: ActionTree<IAnnotatorsState, IRootState> = {
  async getAllAnnotators({ rootGetters, commit }) {
    const annotators = await rootGetters.currentOrganization.getAnnotators()
    commit('setAnnotators', annotators)
  },

  async getAllBPOs({ rootGetters, commit }) {
    const bpos = await rootGetters.currentOrganization.getBPOs()
    commit('setBPOs', bpos)
  },

  async getInvitingMembers({ rootGetters, commit }) {
    const invitingMembers = await rootGetters.currentOrganization.getInvitingMembers()
    commit('setInvitingMembers', invitingMembers)
  },

  async deleteAnnotator({ rootGetters, commit }, annotator: Annotator) {
    const orgId = rootGetters.currentOrganization.id
    await annotator.delete(orgId)
    commit('removeOneAnnotator', annotator)
  },

  async getAccessTokens({ rootState, commit }) {
    const orgId = rootState.currentOrganizationID
    if (!orgId) {
      console.error('currentOrganizationId not found')
      return
    }
    const accessTokens = await AccessToken.getAccessTokens(orgId)
    commit('setAccessTokens', accessTokens)
  },

  async createAccessToken({ rootState, state, commit }, name: string) {
    const orgId = rootState.currentOrganizationID
    if (!orgId) {
      console.error('currentOrganizationId not found')
      return
    }
    const accessToken = await AccessToken.createAccessToken(orgId, name)
    const newAccessTokens = [accessToken, ...state.accessTokens]
    commit('setAccessTokens', newAccessTokens)
    return accessToken
  },

  async deleteAccessToken({ commit, state }, id: number) {
    await AccessToken.deleteAccessToken(id)
    const newAccessTokens = state.accessTokens.filter(it => it.id !== id)
    commit('setAccessTokens', newAccessTokens)
  },

  async inviteNewMember({ rootGetters, commit, state }, payload: { email: string; role: Role }) {
    const newInvitation = await Invitation.createInvitation(
      rootGetters.currentOrganization.id,
      payload.email,
      payload.role.id
    )
    const newInvitingMembers = [...state.invitingMembers]
    newInvitingMembers.push(newInvitation)
    commit('setInvitingMembers', newInvitingMembers)
  },

  async resendInvitation({ commit, state }, target: Invitation) {
    const updated = await target.resendMail()
    const index = await state.invitingMembers.indexOf(target)
    const newInvitingMembers = [...state.invitingMembers]
    newInvitingMembers.splice(index, 1, updated)
    commit('setInvitingMembers', newInvitingMembers)
  },
  async revokeInvitation({ commit, state }, target: Invitation) {
    await target.revoke()
    const newInvitingMembers = state.invitingMembers.filter(it => it.id !== target.id)
    commit('setInvitingMembers', newInvitingMembers)
  }
}

export default class SourceURIScheme {
  static readonly UNKNOWN = new SourceURIScheme(0, 'UNKNOWN')
  static readonly DATALAKE = new SourceURIScheme(1, 'DATALAKE')
  static readonly GCS = new SourceURIScheme(2, 'GCS')
  static readonly HTTPS = new SourceURIScheme(4, 'HTTPS')
  static readonly HTTP = new SourceURIScheme(5, 'HTTP')
  static readonly S3 = new SourceURIScheme(10, 'S3')
  static readonly GS = new SourceURIScheme(20, 'GS')

  static readonly items: SourceURIScheme[] = [
    SourceURIScheme.DATALAKE,
    SourceURIScheme.GCS,
    SourceURIScheme.HTTPS,
    SourceURIScheme.HTTP,
    SourceURIScheme.S3,
    SourceURIScheme.GS
  ]

  static getById(id: number): SourceURIScheme {
    const item = this.items.find(it => it.id === id)
    if (!item) {
      return this.UNKNOWN
    }
    return item
  }

  private constructor(public readonly id: number, public readonly name: string) {}

  toScheme(): string {
    return this.name.toLowerCase() + '://'
  }

  equals(sourceURI?: SourceURIScheme) {
    if (!sourceURI) {
      return false
    }
    return this.id === sourceURI.id
  }
}

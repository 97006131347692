import ProjectStatus from '@/core/models/ProjectStatus'
import { LimitOffsetPaging } from '@/core/models/LimitOffsetPaging'

export class ProjectSort {
  static readonly DeadlineAsc = new ProjectSort('due_date', 'asc')
  static readonly DeadlineDesc = new ProjectSort('due_date', 'desc')
  static readonly IdAsc = new ProjectSort('id', 'asc')
  static readonly IdDesc = new ProjectSort('id', 'desc')
  static readonly NameAsc = new ProjectSort('name', 'asc')
  static readonly NameDesc = new ProjectSort('name', 'desc')
  static readonly StatusAsc = new ProjectSort('status', 'asc')
  static readonly StatusDesc = new ProjectSort('status', 'desc')

  private constructor(public readonly orderBy: string, public readonly order: string) {}

  static get(orderBy: string, order: string) {
    return (
      [
        ProjectSort.DeadlineAsc,
        ProjectSort.DeadlineDesc,
        ProjectSort.IdAsc,
        ProjectSort.IdDesc,
        ProjectSort.NameAsc,
        ProjectSort.NameDesc,
        ProjectSort.StatusAsc,
        ProjectSort.StatusDesc
      ].find(v => v.orderBy === orderBy && v.order === order) || ProjectSort.DeadlineDesc
    )
  }
}

export class ProjectsSearchCondition {
  name: string | null = null
  statuses: ProjectStatus[] = [ProjectStatus.NotStarted, ProjectStatus.InProgress, ProjectStatus.OnHold]
  sortOption: ProjectSort = ProjectSort.IdDesc
  paging?: LimitOffsetPaging

  constructor(init: Partial<ProjectsSearchCondition> = {}) {
    Object.assign(this, init)
  }

  toRouterString() {
    return this.toQueryParameter()
  }

  fromRouterParams(query: { [key: string]: any }) {
    if (query.name) {
      this.name = query.name
    }
    if (query.limit) {
      this.paging = new LimitOffsetPaging(0, Number(query.limit))
    }
    if (query.sort_direction && query.sort_field) {
      this.sortOption = ProjectSort.get(query.sort_direction, query.sort_field)
    }
    if (query.status) {
      const statuses = Array.isArray(query.status) ? query.status : [query.status]
      this.statuses = statuses.map(v => ProjectStatus.getById(Number(v)))
    }
  }

  changePaging(paging: LimitOffsetPaging) {
    this.paging = paging
  }

  toQueryParameter() {
    const params = new URLSearchParams()
    if (this.name) {
      params.set('name', this.name)
    }
    if (this.paging) {
      params.set('offset', String(this.paging.offset))
      params.set('limit', String(this.paging.perPage))
    }
    if (this.sortOption) {
      params.set('sort_direction', String(this.sortOption.order))
      params.set('sort_field', String(this.sortOption.orderBy))
    }
    this.statuses.forEach(v => {
      params.append('status', String(v.id))
    })
    return params.toString()
  }
}

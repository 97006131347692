export enum ERROR_CODE {
  EMAIL_ALREADY_EXISTS = 'user/email_already_exists',
  RESULT_ALREADY_SOLVED = 'result/already_resolved',
  RESULT_UPDATE_STATUS_NOT_ACCEPTABLE = 'result/update_status_not_acceptable',

  NO_ASSIGNABLE_TASK = 'task/assignable_task_not_found',
  TASK_NOT_ASSIGNABLE = 'task/not_assignable',

  NUMBER_OF_TASK_FOR_UNIT_IS_FULLFILLED = 'assignment/already_fulfilled',
  PLATFORM_RESOURCE_NOT_FOUND = 'abeja_platform/resource_not_found',
  PLATFORM_ACCESS_TOKEN_IS_EXPIRED = 'authorization/platform_access_token_expired',

  CANNOT_REVERT_REJECTION = 'result/revert_reject_conflict',

  IMPORT_TASK_IN_PROGRESS_EXISTS = 'import_tasks/already_exist',

  AUTHORIZATION_ONLY_ANNOTATION_MANAGER = 'authorization/only_annotation_manager',
  PROJECT_INVALID_STATUS_TO_EXECUTE = 'project/invalid_status_to_execute',
  INVITATION_TOKEN_INVALID = 'user_invitation/token_invalid',
  USER_NOT_FOUND = 'user/not_found',
  AUTH_TYPE_INCONSISTENT = 'user/auth_type_inconsistent',
  EMAIL_MISMATCH = 'auth/email_mismatch',

  CREDENTIAL_NOT_VERIFIED = 'credential_not_verified',
  TASK_SOURCE_NOT_SUPPORT_SOURCE_URI = 'task_source/not_support_source_uri'
}

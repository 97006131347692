import { IWorkspaceState } from '@/features/workspace/store/index'
import Task from '@/core/models/Task'
import Project from '@/core/models/Project'
import Result from '@/core/models/Result'
import Template from '@/core/models/Template'
import { Work } from '@/core/models/Work'

export const mutations = {
  setCurrentProject(state: IWorkspaceState, payload: Project) {
    state.currentProject = payload
  },
  setCurrentWork(state: IWorkspaceState, payload: Work) {
    state.currentWork = payload
  },
  setCurrentTask(state: IWorkspaceState, payload: Task) {
    state.currentTask = payload
  },
  setCurrentResult(state: IWorkspaceState, payload: Result) {
    state.currentResult = payload
  },
  setCurrentTemplate(state: IWorkspaceState, payload: Template) {
    state.currentTemplate = payload
  }
}

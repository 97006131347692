import { RouteConfig } from 'vue-router'
const route: RouteConfig[] = <Array<RouteConfig>>[
  {
    path: '/projects',
    component: {
      render(c: any) {
        return c('router-view')
      }
    },
    meta: {
      breadcrumb: 'project'
    },
    children: [
      {
        path: '',
        component: () => import('./pages/Projects.vue'),
        name: 'project',
        meta: {
          resource: 'project',
          action: 'list',
          gtm: 'Project List'
        }
      },
      {
        path: 'new',
        name: 'projectCreate',
        component: () => import('./pages/CreateProject.vue'),
        meta: {
          resource: 'project',
          action: 'create',
          breadcrumb: 'projectCreate',
          gtm: 'Create Project'
        }
      },
      {
        path: ':id',
        component: () => import('./pages/ProjectDetail.vue'),
        children: [
          {
            path: 'performance',
            name: 'projectPerformance',
            component: () => import('./pages/ProjectDetail/ProjectPerformance.vue'),
            meta: {
              resource: 'project',
              action: 'view',
              breadcrumb: 'projectPerformance',
              gtm: 'Project Performance'
            }
          },
          {
            path: 'information',
            name: 'projectDetail',
            component: () => import('./pages/ProjectDetail/ProjectInformation.vue'),
            meta: {
              resource: 'project',
              action: 'view',
              breadcrumb: 'projectDetail',
              gtm: 'Project Information'
            }
          },
          {
            path: 'works',
            name: 'projectWorks',
            component: () => import('./pages/ProjectDetail/ProjectWorks.vue'),
            meta: {
              resource: 'work',
              action: 'create', // user can only see this page if they're able to create work
              breadcrumb: 'projectWork',
              gtm: 'Project Work List'
            }
          },
          {
            path: 'manual',
            name: 'projectManual',
            component: () => import('./pages/ProjectDetail/ProjectManual.vue')
          },
          {
            path: '',
            redirect: 'performance'
          }
        ]
      }
    ]
  }
]
export default route

export default class Organization {
  constructor(
    public readonly id: number = 0,
    public readonly type: OrganizationType = OrganizationType.Unknown,
    public readonly name: string = '',
    public readonly platformId?: number
  ) {}
  get isConnectedPlatform() {
    return !!this.platformId
  }
}

export enum OrganizationType {
  Client = 'Client',
  BPO = 'BPO',
  Unknown = 'Unknown'
}

import Project from '@/core/models/Project'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import { Module } from 'vuex'
import { IRootState } from '@/store'
import { Work } from '@/core/models/Work'
import TaskCount from '@/core/models/TaskCount'
import DatalakeChannel from '@/core/models/DatalakeChannel'
import TaskImportHistory from '@/core/models/TaskImportHistory'
import { WorkBlock } from '@/core/models/WorkBlock'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'
import WorkSource from '@/core/models/WorkSource'

export interface IWorkState {
  currentUserAssignedWorkIDs?: number[]
  projects: Project[]
  currentWork?: Work
  currentWorkBlock?: WorkBlock
  taskCount?: TaskCount
  currentChannel?: DatalakeChannel
  importHistoryMap: Map<string, TaskImportHistory>
  assignableAnnotators: Annotator[]
  assignableBPOs: BPO[]
  workSources: Array<WorkSource>
}

const state: IWorkState = {
  currentUserAssignedWorkIDs: undefined,
  projects: [],
  currentWork: undefined,
  currentWorkBlock: undefined,
  taskCount: new TaskCount(0, 0),
  currentChannel: undefined,
  importHistoryMap: new Map<string, TaskImportHistory>(),
  assignableAnnotators: [],
  assignableBPOs: [],
  workSources: []
}

const work: Module<IWorkState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default work

import { httpClient } from '../common/HttpClient'
export default class DatalakeChannel {
  static mapObject(data: any): DatalakeChannel {
    return new DatalakeChannel(data.platform_channel_id, data.name, data.description, 0, 0)
  }

  public static async getAllChannels(organizationID: number): Promise<DatalakeChannel[]> {
    return httpClient.get(`/api/v1/organizations/${organizationID}/channels`).then((response: any) => {
      return (response || []).map((d: any) => DatalakeChannel.mapObject(d))
    })
  }

  public static async getChannel(organizationID: number, channelID: number): Promise<DatalakeChannel> {
    const response = await httpClient.get(`/api/v1/organizations/${organizationID}/channels/${channelID}`)
    return DatalakeChannel.mapObject(response)
  }

  static async createDatalakeChannel(
    organizationID: number,
    newDatalakeChannel: DatalakeChannel
  ): Promise<DatalakeChannel> {
    const { name, description } = newDatalakeChannel
    const response = await httpClient.post(`/api/v1/organizations/${organizationID}/channels`, {
      name,
      description
    })
    return DatalakeChannel.mapObject(response)
  }

  public additionalTasks = 0
  public isSynced = false
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public fileCount: number = 0,
    public taskCount: number = 0
  ) {
    this.additionalTasks = this.fileCount - this.taskCount
    this.isSynced = this.additionalTasks === 0
  }

  public async getSampleData(orgID: string): Promise<any> {
    try {
      const response: any = await httpClient.get(`/api/v1/organizations/${orgID}/channels/${this.id}/samples`)
      if (response && response.length) {
        // Reduce sample in client site. Later want to do server site
        return response.slice(0, 10).map((item: any) => ({
          url: item.download_url
        }))
      }
    } catch (err) {
      console.error(err)
    }
    return []
  }
}

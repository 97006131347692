import * as LogRocket from 'logrocket'
import User from '../core/models/User'
import { setLogRocketSession } from './sentry'

if (process.env.VUE_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_ID)
  LogRocket.getSessionURL(setLogRocketSession)
}

export const setUserInLog = (user: User, organizationID: number) => {
  if (process.env.VUE_APP_LOGROCKET_ID) {
    LogRocket.identify(user.email, {
      id: user.id,
      name: user.name,
      organizationID
    })
  }
}








import Vue from 'vue'
import { mapMutations } from 'vuex'
import { localStorage } from './core/common/LocalStorage'
import i18n from './i18n'
export default Vue.extend({
  created() {
    const supportLocales = ['en', 'ja']
    let currentLocale = localStorage.getLocale() || 'ja'
    if (!supportLocales.includes(currentLocale)) {
      currentLocale = 'ja'
    }
    i18n.locale = currentLocale
    this.changeLocale(currentLocale)
    this.checkBrowser()
  },
  methods: {
    ...mapMutations(['changeLocale']),
    checkBrowser() {
      const agent = navigator.userAgent
      if (agent.indexOf('Chrome') < 0) {
        this.$toast({
          type: 'warning',
          text: this.$t('notification.not_supported_browser').toString(),
          showConfirmButton: true,
          timer: 180000 // 3 min
        })
      }
    }
  }
})

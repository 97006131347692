import ConfirmModal from '@/components/common/ConfirmModal.vue'
import _Vue from 'vue'

interface ConfirmOptions {
  text?: string
  title?: string
  onConfirm?: () => void
}

declare module 'vue/types/vue' {
  interface Vue {
    $confirm: {
      confirm: (options: ConfirmOptions) => void
    }
  }
}

const Modal: {
  EventBus?: _Vue
  install: (Vue: typeof _Vue, options?: any) => void
} = {
  install(Vue: typeof _Vue) {
    this.EventBus = new Vue()
    Vue.component('ConfirmModal', ConfirmModal)

    Vue.prototype.$confirm = {
      confirm(params: ConfirmOptions) {
        Modal.EventBus!.$emit('show', params)
      }
    }
  }
}

export default Modal

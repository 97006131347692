import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import numeral from 'numeral'
import i18n from './i18n'

/**
 *  Datetime format by i18n DatetimeFormat
 *  https://github.com/iamkun/dayjs
 */
const formatDate = (date: Date, kind: string) => {
  if (!date) {
    return ''
  }
  return i18n.d(dayjs(date).toDate(), kind)
}
// YYYY MM DD
Vue.filter('date', (value: Date) => formatDate(value, 'short'))
// YYYY MM DD HH SS
Vue.filter('datetime', (value: Date) => formatDate(value, 'long'))
// YYYY MM DD WW HH SS
Vue.filter('datetimeW', (value: Date) => formatDate(value, 'longWd'))
Vue.prototype.$formatDate = (value: Date) => formatDate(value, 'short')

/**
 *  Datetime format by Dayjs
 *  https://github.com/iamkun/dayjs
 */
const dateFromToday = (value: Date) => {
  const target = dayjs(value)
  const diff = dayjs(Date.now()).diff(target, 'day')
  if (diff < 0) {
    return i18n.d(value, 'short')
  } else if (0 === diff) {
    return i18n.t('datetime.today')
  } else if (diff < 5) {
    return i18n.t('datetime.daysAgo', { days: diff })
  } else {
    return i18n.d(value, 'short')
  }
}
Vue.filter('dateFromToday', dateFromToday)
Vue.prototype.$dateFromToday = dateFromToday

/**
 *  Number format by numeral
 *  http://numeraljs.com/
 */
const formatNumber = (value: number) => {
  return numeral(value).format('0,0')
}
Vue.filter('number', formatNumber)
Vue.prototype.$formatNumber = formatNumber

Vue.filter('toRoman', (num: number) => {
  let result = ''
  const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
  const roman = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I']
  for (let i = 0; i <= decimal.length; i++) {
    while (num % decimal[i] < num) {
      result += roman[i]
      num -= decimal[i]
    }
  }
  return result
})

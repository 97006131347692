import Project from '@/core/models/Project'
import { ProjectsSearchCondition } from '@/core/models/ProjectsSearchCondition'
import { ActionTree } from 'vuex'
import { IRootState } from '@/store'
import { IWorkState } from './index'
import { Work } from '@/core/models/Work'
import DatalakeChannel from '@/core/models/DatalakeChannel'
import TaskCount from '@/core/models/TaskCount'
import { WorkBlock } from '@/core/models/WorkBlock'
import IAssignable from '@/core/models/IAssignable'
import { ability } from '@/ability'
import Annotator from '@/core/models/Annotator'
import Assignments from '@/core/models/Assignments'
import { IExportEvent } from '../../../core/interfaces/IExportEvent'

export const actions: ActionTree<IWorkState, IRootState> = {
  async getAssignedWorks({ commit, rootState }) {
    commit('setCurrentAssignWorks', undefined)
    const assignedWorks = await rootState.logInUser!.getAssignedWork(rootState.currentOrganizationID!)
    commit(
      'setCurrentAssignWorks',
      assignedWorks.map(item => item.id)
    )
  },
  async getAllProjects({ commit, rootState }) {
    const res = await Project.getProjects(rootState.currentOrganizationID!, new ProjectsSearchCondition())
    commit('setProjects', res.results || [])
  },
  async assignMe({ state, commit, rootState }, work: Work) {
    const newWork = await rootState.logInUser!.assignMeToWork(
      work.projectID!,
      work.id,
      rootState.currentOrganizationID!
    )
    commit('addCurrentAssignWorks', newWork)
    const { logInUser } = rootState
    const { currentWork } = state
    if (logInUser && currentWork && currentWork.id === work.id) {
      commit('addAssignedAnnotator', logInUser.toAnnotator())
    }
  },
  async createWork({ rootState, dispatch }, payload: { newWork: Work; isAutoImport: boolean }) {
    if (!rootState.currentOrganizationID!) {
      return
    }
    const newWork = await payload.newWork.create()

    if (newWork.importable && payload.isAutoImport) {
      newWork.createImportTask()
    }
    dispatch('getAssignedWorks')
    return newWork
  },
  async getCurrentWork({ getters, state, commit, rootState, dispatch }, id: number) {
    commit('setCurrentWork', null)
    commit('setTaskCount', new TaskCount(0, 0))
    const work = await Work.getCurrentWork(id)
    commit('setCurrentWork', work)

    await dispatch('getWorkBlock', {
      projectId: work.project!.id,
      workBlockId: work.workBlockID!
    })

    const assignedAnnotators: IAssignable[] = await work.getAssignedAnnotators(rootState.currentOrganizationID!)
    let assignedUnits: IAssignable[] = []
    if (ability.can('list', 'assigned_bpo')) {
      assignedUnits = await work.getAssignedUnits(rootState.currentOrganizationID!)
    }
    commit('updateAssignedAnnotatorInCurrentWork', assignedAnnotators.concat(assignedUnits))
    // dispatch('getTaskCount')
  },

  async getTaskCount({ state, commit }) {
    if (state.currentWork?.importable && ability.can('create', 'importtask')) {
      const taskCount = await state.currentWork?.getTaskCount()
      commit('setTaskCount', taskCount)
    }
  },

  async getWorkBlock({ commit, rootState }, { projectId, workBlockId }: { projectId: number; workBlockId: number }) {
    commit('setCurrentWorkBlock', null)
    const workBlock = await WorkBlock.getWorkBlock({
      orgId: rootState.currentOrganizationID!,
      projectId,
      workBlockId
    })
    commit('setCurrentWorkBlock', workBlock)
  },

  async createImportTasks({ commit, state }) {
    await state.currentWork?.createImportTask()
    const importHistory = state.currentWork?.taskImportHistory
    commit('updateImportHistoryInCurrentProject', importHistory)
  },

  async getCurrentChannel({ commit, state, rootState }) {
    const { currentWork } = state
    if (!currentWork?.isDataLake) return
    const { currentOrganizationID: oid } = rootState
    if (!oid) return
    const { platformChannelID: cid } = currentWork
    if (!cid) return
    const currentChannel = await DatalakeChannel.getChannel(oid, cid)
    commit('setCurrentChannel', { currentChannel })
  },

  async getImportTasks({ commit, state, rootState }) {
    const importTasks = await state.currentWork!.getImportTasks(rootState.currentOrganizationID!)
    commit('updateImportHistoryInCurrentProject', importTasks)
    commit('setImportHistoryMap', { importTasks })
  },

  clearCurrentChannel({ commit }) {
    commit('setCurrentChannel', { currentChannel: null })
  },

  clearCurrentWork({ commit }) {
    commit('setCurrentWork', null)
    commit('setCurrentWorkBlock', null)
  },

  async getAssignableAnnotator({ rootGetters, commit, state }) {
    const assignableAnnotators: IAssignable[] = await rootGetters.currentOrganization.getAnnotators()
    commit('setAssignableAnnotators', assignableAnnotators)

    if (ability.can('list', 'assigned_bpo')) {
      const assignableBPOs: IAssignable[] = await rootGetters.currentOrganization.getBPOs()
      commit('setAssignableBPOs', assignableBPOs)
    }
  },
  async saveAssign({ rootState, commit, state, getters }, assigns: Assignments) {
    if (!state.currentWork) return
    const annotators = assigns.assignments.filter(a => a instanceof Annotator)
    const bpos = assigns.bpos

    const assignedAnnotators: IAssignable[] = await state.currentWork.assignAnnotator(
      rootState.currentOrganizationID!,
      annotators
    )

    let assignedUnits: IAssignable[] = []
    if (ability.can('update', 'assigned_bpo')) {
      assignedUnits = await state.currentWork.assignBPO(rootState.currentOrganizationID!, bpos)
    }
    commit('updateAssignedAnnotatorInCurrentWork', assignedAnnotators.concat(assignedUnits))
  },

  async getWorkSources({ commit, state }) {
    if (!state.currentWork) {
      return
    }
    const workSources = await state.currentWork.getWorkSources()
    commit('setWorkSources', { workSources: workSources.reverse() })
  },
  async updateWork({ commit, state, dispatch, rootState }, newWork) {
    if (!state.currentWork || !rootState.currentOrganizationID) {
      return
    }
    const work = await state.currentWork.updateWork(newWork, rootState.currentOrganizationID)
    commit('setCurrentWork', work)
  },
  async deleteCurrentWork({ commit, state, rootState }) {
    if (!state.currentWork || !rootState.currentOrganizationID) {
      return
    }
    await state.currentWork.deleteWork(rootState.currentOrganizationID)
    commit('setCurrentWork', null)
  },
  async getAllExportEvents({ state, rootState }) {
    if (!state.currentWork) {
      return
    }
    const exportFileEventsPromise = state.currentWork.getExportEvents(rootState.currentOrganizationID!)
    const exportDatasetEventsPromise = state.currentWork.getDatasetExportEvents(rootState.currentOrganizationID!)
    const [exportFileEvents, exportDatasetEvents] = await Promise.all([
      exportFileEventsPromise,
      exportDatasetEventsPromise
    ])
    // merge export events
    const allEvents: IExportEvent[] = [...exportFileEvents, ...exportDatasetEvents]
    allEvents.sort((a: IExportEvent, b: IExportEvent) => {
      return new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
    })
    return allEvents
  }
}

import ResultStatus from './ResultStatus'
class CommentType {
  static readonly Simple = new CommentType(1, 'simple')
  static readonly ReviewComplete = new CommentType(11, 'completed')
  static readonly ReviewReject = new CommentType(12, 'rejected')
  static readonly Pending = new CommentType(13, 'pending')
  static readonly Discard = new CommentType(14, 'discard')
  static readonly RevertComplete = new CommentType(21, 'revert-complete')
  static readonly RevertReject = new CommentType(22, 'revert-reject')
  static readonly ResolvePending = new CommentType(23, 'resolve-pending')
  static readonly RevertDiscard = new CommentType(24, 'revert-discard')
  static readonly ReEdit = new CommentType(25, 're-edit')
  static readonly ReAssign = new CommentType(26, 're-assign')

  static readonly Unknown = new CommentType(-1, 'Unknown')

  static readonly items: CommentType[] = [
    CommentType.Simple,
    CommentType.ReviewComplete,
    CommentType.ReviewReject,
    CommentType.Pending,
    CommentType.Discard,
    CommentType.RevertComplete,
    CommentType.RevertReject,
    CommentType.ResolvePending,
    CommentType.RevertDiscard,
    CommentType.ReEdit,
    CommentType.ReAssign
  ]

  static readonly itemForReview: CommentType[] = [
    CommentType.Pending,
    CommentType.Discard,
    CommentType.RevertComplete,
    CommentType.RevertReject,
    CommentType.RevertDiscard
  ]

  static readonly itemForSelfReview: CommentType[] = [CommentType.RevertComplete, CommentType.RevertReject]

  static getById(id: number): CommentType {
    const r = CommentType.items.find(s => s.id === id)
    return r || CommentType.Unknown
  }

  private constructor(public readonly id: number, public readonly name: string) {}

  equals(c: CommentType) {
    return c && this.id === c.id
  }
  getResultStatus() {
    const COMMENT_TYPE_MAP_TO_RESULT_STATUS = {
      [CommentType.ReviewComplete.id]: ResultStatus.Complete,
      [CommentType.ReviewReject.id]: ResultStatus.Reject,
      [CommentType.Pending.id]: ResultStatus.Pending,
      [CommentType.Discard.id]: ResultStatus.Discard,
      [CommentType.RevertComplete.id]: ResultStatus.InReview,
      [CommentType.RevertReject.id]: ResultStatus.InReview,
      [CommentType.ResolvePending.id]: ResultStatus.InProgress,
      [CommentType.RevertDiscard.id]: ResultStatus.Pending
    }
    return COMMENT_TYPE_MAP_TO_RESULT_STATUS[this.id]
  }
}

export default CommentType

import { GetterTree } from 'vuex'
import { IRootState } from '@/store/'
import { IWorkState } from './index'
import { AssignableSelections } from '@/core/models/IAssignable'

export const getters: GetterTree<IWorkState, IRootState> = {
  assignableSelections(state): AssignableSelections {
    const annotators = state.assignableAnnotators.filter(a => a.isAnnotator)
    const managers = state.assignableAnnotators.filter(a => a.isAnnotatorManager)
    const bpos = state.assignableBPOs

    return { annotators, managers, bpos }
  },
  hasOwnedProject(state, _, rootState) {
    if (!state.currentWork) {
      return false
    }
    if (!rootState.currentOrganizationID) {
      return false
    }
    return state.currentWork.project?.ownerOrganizationId === rootState.currentOrganizationID
  }
}

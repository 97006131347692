export class TaskImportHistoryStatus {
  static readonly InQueue = new TaskImportHistoryStatus(1, 'In queue')
  static readonly InProgress = new TaskImportHistoryStatus(2, 'In progress')
  static readonly Failure = new TaskImportHistoryStatus(3, 'Failure')
  static readonly Completed = new TaskImportHistoryStatus(4, 'Completed')
  static readonly Unknown = new TaskImportHistoryStatus(0, 'Unknown')

  static readonly STATUS = [
    TaskImportHistoryStatus.InQueue,
    TaskImportHistoryStatus.InProgress,
    TaskImportHistoryStatus.Failure,
    TaskImportHistoryStatus.Completed
  ]

  static getStatusById(id: number) {
    const status = TaskImportHistoryStatus.STATUS.find(r => r.id === id)
    return status || new TaskImportHistoryStatus(id, 'Unknown')
  }

  private constructor(public id: number, public status: string) {}
}

export default interface SearchOption {
  toQueryParameter(): string
  toSummaryQueryParameter(): string
}

export class EmptySearchOption implements SearchOption {
  toQueryParameter(): string {
    return ''
  }
  toSummaryQueryParameter(): string {
    return ''
  }
}

import { Module } from 'vuex'
import Project from '@/core/models/Project'
import { IRootState } from '@/store/'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import Annotator from '@/core/models/Annotator'
import BPO from '@/core/models/BPO'
import TaskCount from '@/core/models/TaskCount'
import TaskImportHistory from '@/core/models/TaskImportHistory'
import WorkSource from '@/core/models/WorkSource'
import DatalakeChannel from '@/core/models/DatalakeChannel'
import Template from '@/core/models/Template'

export interface IProjectState {
  currentProject: Project | null
  assignableAnnotators: Annotator[]
  assignableBPOs: BPO[]
  templates: Template[]
}

const state: IProjectState = {
  currentProject: null,
  assignableAnnotators: [],
  assignableBPOs: [],
  templates: []
}

const project: Module<IProjectState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default project

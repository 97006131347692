import { Work } from './Work'
import { TaskImportHistoryStatus } from './TaskImportHistoryStatus'
import { SourceURI } from './SourceURI'

export interface TaskImportHistoryPayload {
  work: any // TODO
  id: number
  work_id: number
  user: any // TODO
  created_at: string
  updated_at: string
  finished_at: string | null
  source_uri: {
    host: string
    scheme: number
    uri_path: string
  }
  task_count: number
  status: number
}

export default class TaskImportHistory {
  static mapObject(data: TaskImportHistoryPayload): TaskImportHistory {
    const work = Work.mapObject(data.work)
    const taskImport = new TaskImportHistory(work)
    taskImport.id = data.id
    taskImport.workID = data.work_id
    taskImport.userID = data.user.id
    taskImport.userName = data.user.name
    taskImport.createdAt = new Date(data.created_at)
    taskImport.updatedAt = new Date(data.updated_at)
    taskImport.finishedAt = data.finished_at ? new Date(data.finished_at) : null
    if (data.source_uri) {
      taskImport.sourceURI = new SourceURI(data.source_uri)
    }
    taskImport.taskCount = data.task_count
    taskImport.status = TaskImportHistoryStatus.getStatusById(data.status)
    return taskImport
  }
  id = 0
  workID = 0
  userID = 0
  userName = ''
  additionalTasks = 0
  createdAt?: Date
  updatedAt?: Date
  finishedAt: Date | null = null
  sourceURI: SourceURI | null = null
  status: TaskImportHistoryStatus = TaskImportHistoryStatus.Unknown
  work: Work
  taskCount = 0
  constructor(work: Work) {
    this.work = work
  }

  get isImporting(): boolean {
    return [TaskImportHistoryStatus.InProgress.id, TaskImportHistoryStatus.InQueue.id].includes(this.status.id)
  }
}

import { httpClient } from '../common/HttpClient'
import { TemplatePermissionScope } from '@/core/models/TemplatePermissionScope'
import TemplateIcons from '@/core/models/TemplateIcons'
import { ResultSchemaType } from './ResultSchemaType'
import { parseTemplateManifest, TemplateManifest } from '@abeja-inc/annotation-js-sdk'

const LOCAL_TEMPLATE_URL = process.env.VUE_APP_LOCAL_TEMPLATE_URL
const manifestCache: Map<string, TemplateManifest> = new Map()

export default class Template {
  id?: number
  name = ''
  ownerOrganizationID?: number
  sourceType?: number
  permissionScope?: number // TODO: Create enum
  resultSchemaType?: ResultSchemaType
  resultSchema?: any
  hostURL = ''
  createdAt?: Date
  updatedAt?: Date
  image: any // TODO: Change to Image URL
  icons?: TemplateIcons
  fileResultEnabled = false
  platformDatasetType = ''

  private constructor() {}

  static mapObject(data: any): Template {
    const template = new Template()
    template.id = data.id
    template.name = data.name
    template.ownerOrganizationID = data.owner_organization_id
    template.sourceType = data.source_type
    template.permissionScope = data.permission_scope
    template.resultSchema = data.result_schema
    template.resultSchemaType = data.result_schema_type
    template.fileResultEnabled = !!data.file_result_enabled
    template.platformDatasetType = data.platform_dataset_type

    // If there is a VUE_APP_LOCAL_TEMPLATE_URL in the configuration file, use that URL for all templates
    // ex)  http://localhost:8081
    template.hostURL = LOCAL_TEMPLATE_URL || data.host_url
    if (data.created_at) {
      template.createdAt = new Date(data.created_at)
    }
    if (data.updated_at) {
      template.updatedAt = new Date(data.updated_at)
    }
    template.icons = new TemplateIcons(template.hostURL)
    return template
  }

  private static async getPublicTemplate(): Promise<Template[]> {
    const response: any = await httpClient.get(`/api/v1/templates`)
    return (response.data || []).map((item: any) => Template.mapObject(item))
  }

  private static async getPrivateTemplate(organizationID: number): Promise<Template[]> {
    const response: any = await httpClient.get(
      `/api/v1/organizations/${organizationID}/templates?scope=${TemplatePermissionScope.Private.id}`
    )
    return (response.data || []).map((item: any) => Template.mapObject(item))
  }

  static async getAllTemplates(organizationID: number): Promise<Template[]> {
    const [publicTemplates, privateTemplates] = await Promise.all([
      this.getPublicTemplate(),
      this.getPrivateTemplate(organizationID)
    ])
    return publicTemplates.concat(privateTemplates)
  }

  public async getManifest(): Promise<TemplateManifest> {
    let manifest = manifestCache.get(this.hostURL)
    if (!manifest) {
      const response: any = await fetch(`${this.hostURL}/template.manifest.json`, {
        method: 'GET',
        mode: 'cors'
      }).then(response => response.json())
      manifest = parseTemplateManifest(response)
      manifestCache.set(this.hostURL, manifest)
    }
    return manifest
  }

  equals(template: Template) {
    return !!template && this.id === template.id
  }

  get hostDomain() {
    const parts = this.hostURL.split('/')
    return parts[0] + '//' + parts[2]
  }

  get isPublic() {
    return this.permissionScope == TemplatePermissionScope.Public.id
  }

  get isSchemaSelectable() {
    return this.resultSchemaType === ResultSchemaType.Selectable
  }
}

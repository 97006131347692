import { GetterTree } from 'vuex'
import { IMessageState } from '.'
import { IRootState } from '@/store/'

export const getters: GetterTree<IMessageState, IRootState> = {
  newMessageCount(state): number {
    return state.notifications.filter(item => !item.read).length
  },
  newAnnoucementsCount(state, _, rootState): number {
    if (!rootState.userProfile || !state.announcements) {
      return 0
    }
    const latest = rootState.userProfile.lastReadAnnounce
    if (!latest) {
      return state.announcements.length
    }
    return state.announcements.filter((item: any) => item.publishTime.seconds > latest.seconds).length
  }
}

import { httpClient } from '../common/HttpClient'
import SearchOption from '../interfaces/SearchOption'
import { EmptySearchOption } from '../interfaces/SearchOption'

export class Paginator<T> {
  searchCondition: SearchOption = new EmptySearchOption()
  cursor?: number
  mapObjectFunc: (data: any) => T
  baseURL: string
  count?: number

  constructor(baseURL: string, searchCondition: SearchOption, mapObjectFunc: (data: any) => T) {
    this.mapObjectFunc = mapObjectFunc
    this.baseURL = baseURL
    this.searchCondition = searchCondition
  }

  async getNextPage(): Promise<T[]> {
    const query = this.buildQueryString()
    const data: any = await httpClient.get<[]>(`${this.baseURL}${query}`)
    const newData = data.data.map((d: any) => this.mapObjectFunc(d))
    this.cursor = data.meta.next
    this.count = data.meta.count || null
    return newData
  }

  buildQueryString(): string {
    let query = this.searchCondition.toQueryParameter()
    if (this.cursor) {
      query = query + '&' + `cursor=${this.cursor}`
    }
    if (query) {
      query = '?' + query
    }

    return query
  }
}

class ProjectStatus {
  static readonly NotStarted = new ProjectStatus(1, 'NotStarted')
  static readonly InProgress = new ProjectStatus(2, 'InProgress')
  static readonly OnHold = new ProjectStatus(3, 'OnHold')
  static readonly Completed = new ProjectStatus(4, 'Completed')
  static readonly Unknown = new ProjectStatus(-1, 'Unknown')

  static readonly items: ProjectStatus[] = [
    ProjectStatus.NotStarted,
    ProjectStatus.InProgress,
    ProjectStatus.OnHold,
    ProjectStatus.Completed
  ]

  static getById(id: number): ProjectStatus {
    const r = ProjectStatus.items.find(s => s.id === id)
    if (!r) {
      return ProjectStatus.Unknown
    }
    return r
  }

  get canWork(): boolean {
    return this.id === ProjectStatus.InProgress.id
  }

  get canReview(): boolean {
    return this.id !== ProjectStatus.NotStarted.id
  }

  get canEditResult(): boolean {
    return this.id === ProjectStatus.InProgress.id
  }

  get canManageAssignment(): boolean {
    return [ProjectStatus.InProgress, ProjectStatus.OnHold].includes(this)
  }

  private constructor(public readonly id: number, public readonly name: string) {}
}

export default ProjectStatus

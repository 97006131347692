import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import Result from '@/core/models/Result'
import Project from '@/core/models/Project'
import ReviewStream from '@/core/models/ReviewStream'
import User from '@/core/models/User'
import { Paginator } from '@/core/models/CursorPaginator'
import { Work } from '@/core/models/Work'

export interface IReviewState {
  project: Project | null
  currentWork: Work | null
  annotators: User[]
  results: Result[]
  selectedReviewStream: ReviewStream | null
  selectedResult: Result | null
  resultPaginator: Paginator<Result> | null
}

const state: IReviewState = {
  project: null,
  currentWork: null,
  annotators: [],
  results: [],
  selectedReviewStream: null,
  selectedResult: null,
  resultPaginator: null
}

const project = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default project

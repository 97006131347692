export default class TaskCount {
  totalTask = 0
  importedTask = 0

  constructor(totalTask: number, importedTask: number) {
    this.totalTask = totalTask
    this.importedTask = importedTask
  }

  get numberOfNewTask() {
    return Math.max(0, this.totalTask - this.importedTask)
  }

  get isEmpty() {
    return this.totalTask === 0
  }

  get hasNew(): boolean {
    return 0 < this.numberOfNewTask
  }
}

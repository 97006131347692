import Project from '@/core/models/Project'
import { MutationTree } from 'vuex'
import { IWorkState } from './index'
import { Work } from '@/core/models/Work'
import IAssignable from '@/core/models/IAssignable'
import TaskCount from '@/core/models/TaskCount'
import TaskImportHistory from '@/core/models/TaskImportHistory'
import cloneDeep from 'lodash/cloneDeep'
import { WorkBlock } from '@/core/models/WorkBlock'
import Annotator from '@/core/models/Annotator'
import Assignments from '@/core/models/Assignments'
import WorkSource from '@/core/models/WorkSource'

export const mutations: MutationTree<IWorkState> = {
  setCurrentAssignWorks(state: IWorkState, payload: number[] | undefined) {
    state.currentUserAssignedWorkIDs = payload
  },
  setProjects(state: IWorkState, payload: Project[]) {
    state.projects = payload
  },
  addCurrentAssignWorks(state, work: Work) {
    if (!state.currentUserAssignedWorkIDs!.includes(work.id)) {
      state.currentUserAssignedWorkIDs = state.currentUserAssignedWorkIDs!.concat([work.id])
    }
  },
  addAssignedAnnotator(state, annotator: IAssignable) {
    // TODO: Implement it
  },
  setCurrentWork(state: IWorkState, work: Work) {
    state.currentWork = work
  },
  setTaskCount(state, taskCount: TaskCount) {
    state.taskCount = taskCount
  },
  setCurrentChannel(state, { currentChannel }) {
    state.currentChannel = currentChannel
  },
  updateImportHistoryInCurrentProject(state, importTasks: TaskImportHistory[]) {
    state.currentWork!.taskImportHistory = importTasks
    state.currentWork = cloneDeep<Work>(state.currentWork!)
  },
  setImportHistoryMap(state, { importTasks }) {
    const newMap = new Map(state.importHistoryMap)
    importTasks.forEach((importTask: TaskImportHistory) => {
      if (!importTask.sourceURI) {
        return
      }
      newMap.set(importTask.sourceURI.uriPath, importTask)
    })
    state.importHistoryMap = newMap
  },
  setCurrentWorkBlock(state: IWorkState, workblock: WorkBlock) {
    state.currentWorkBlock = workblock
  },
  setAssignableAnnotators(state, payload: Annotator[]) {
    state.assignableAnnotators = payload
  },
  setAssignableBPOs(state, payload: IAssignable[]) {
    state.assignableBPOs = payload
  },
  updateAssignedAnnotatorInCurrentWork(state, assignment: IAssignable[]) {
    if (!state.currentWork) {
      return
    }
    state.currentWork.assignments = new Assignments(assignment)
    state.currentWork = cloneDeep<Work>(state.currentWork!)
  },

  setWorkSources(state, { workSources }: { workSources: Array<WorkSource> }) {
    state.workSources = workSources
  }
}

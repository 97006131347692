import TemplateIcons from '@//core/models/TemplateIcons'

export class TemplateInfo {
  constructor(public readonly id: number, public readonly name: string, public readonly icons: TemplateIcons) {}
}

export default class ProjectSummary {
  get rejectRate(): number {
    if (this.totalCount === 0) {
      return 0
    }
    const rate = (this.rejectCount * 100) / this.totalCount
    return Number(rate.toFixed(1))
  }

  constructor(
    public readonly projectName: string,
    public readonly templateInfo: TemplateInfo,
    public readonly totalCount: number,
    public readonly completeCount: number,
    public readonly rejectCount: number,
    public readonly projectId: number
  ) {}
}
